import { type MutableRefObject, useCallback } from 'react';
import type { ColumnState, ColumnVisibleEvent } from '@ag-grid-community/core';

import type { DataGridProps } from '../../DataGrid';
import type { ColumnVisibilityModel, GridOptions, Row } from '../../types';
import { isColGroupDef } from '../../utils';
import { useEffectAfterFirstRender, useGridLogger, useInitialiseState } from '../utils';

export const useColumnVisibilityFeature = <R extends Row>(
  gridRef: MutableRefObject<GridOptions<R>>,
  props: Pick<
    DataGridProps<R>,
    'columnGrouping' | 'columns' | 'columnVisibility' | 'onColumnVisibilityChange'
  >
) => {
  const logger = useGridLogger(gridRef, 'useColumnVisibilityFeature');

  const { columnGrouping = [], columns, columnVisibility = {}, onColumnVisibilityChange } = props;

  const handleColumnVisibilityChange = useCallback(
    (e: ColumnVisibleEvent) => {
      const visibilityModel: ColumnVisibilityModel = {};
      const processedFields: Record<string, boolean> = {};

      e.columnApi.getAllGridColumns().forEach(col => {
        let field: string;

        const parent = col.getOriginalParent();
        if (parent && Object.keys(parent.getColGroupDef() ?? {}).length > 0) {
          field = parent.getGroupId();
        } else {
          field = col.getId();
        }

        if (processedFields[field]) return;
        visibilityModel[field] = col.isVisible();
        processedFields[field] = true;
      });

      onColumnVisibilityChange?.(visibilityModel);
    },
    [onColumnVisibilityChange]
  );

  useInitialiseState(() => {
    Object.entries(columnVisibility).forEach(([field, isVisible]) => {
      const colDef = gridRef.current.columnDefs?.find(col =>
        isColGroupDef<R>(col) ? col.groupId === field : col.field === field
      );

      if (!colDef) return;

      if (isColGroupDef<R>(colDef)) {
        colDef.children.forEach(leafColumn => {
          const newLeafColumn = leafColumn;

          const column = columns.find(col => col.field === leafColumn.field);
          if (column && column.hideable !== false) {
            newLeafColumn.hide = !isVisible;
          }
        });
      } else {
        const column = columns.find(col => col.field === colDef.field);
        if (column && column.hideable !== false) {
          colDef.hide = !isVisible;
        }
      }
    });

    gridRef.current.onColumnVisible = handleColumnVisibilityChange;
  });

  useEffectAfterFirstRender(() => {
    logger.log('Updating column visibility');

    const visibilityCriteria: ColumnState[] = [];

    Object.entries(columnVisibility).forEach(([field, isVisible]) => {
      const group = columnGrouping.find(group => group.groupId === field);
      if (group) {
        group.children.forEach(leafColumn => {
          const column = columns.find(col => col.field === leafColumn.field);
          if (column && column.hideable !== false) {
            visibilityCriteria.push({
              colId: column.field,
              hide: !isVisible,
            });
          }
        });
      } else {
        const column = columns.find(col => col.field === field);
        if (column && column.hideable !== false) {
          visibilityCriteria.push({
            colId: field,
            hide: !isVisible,
          });
        }
      }
    });

    gridRef.current.columnApi?.applyColumnState({
      state: visibilityCriteria,
      defaultState: {
        hide: false,
      },
    });
  }, [columns, columnGrouping, columnVisibility]);

  useEffectAfterFirstRender(() => {
    throw new TypeError('Controlling `onColumnVisibilityChange` is not supported.');
  }, [handleColumnVisibilityChange]);
};
