import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { lockTotals } from 'src/actions/lockTotals';
import { setTotals } from 'src/actions/setTotals';
import { TopTotalsRow } from 'src/actions/TopTotalsRow';
import DeleteSaleModal from 'src/components/BrandSales/DeleteSaleModal';
import { ColumnVisibilityMenu } from 'src/components/ColumnVisibilityMenu';
import { Container } from 'src/components/Container';
import {
  DataGrid,
  type FillOperationParams,
  type unsafe_CellEditingStoppedEvent,
  type unsafe_GetContextMenuItemsParams,
  type unsafe_GridApi,
  type unsafe_GridOptions,
  type unsafe_NewValueParams,
  type unsafe_PostSortRowsParams,
  type unsafe_RowClassParams,
  type unsafe_RowNode,
} from 'src/components/DataGrid';
import { SwapBrandLineDialog } from 'src/components/Dialogs/SwapBrandLineDialog';
import { SwapDistributorDialog } from 'src/components/Dialogs/SwapDistributorDialog';
import { ExportReportTrigger } from 'src/components/ExportReport';
import { FilterMenu } from 'src/components/FilterMenu';
import { GridSearch } from 'src/components/GridSearch';
import { GroupingMenu } from 'src/components/GroupingMenu';
import ModalDialog from 'src/components/ModalDialog';
import AddBrandLineModal from 'src/components/Modals/AddBrandLineModal';
import { SidebarBody, SidebarHeader, SidebarTitle, StyledSidebar } from 'src/components/Sidebar';
import { ViewSectionSwitcher } from 'src/components/ViewSectionSwitcher';
import { ViewSwitcher } from 'src/components/ViewSwitcher';
import { Content } from 'src/css/styled-components';
import { isFeatureFlagSet } from 'src/feature-flags';
import { useDataGridUnmountKey } from 'src/hooks/useDataGridUnmountKey/useDataGridUnmountKey';
import { useRecords } from 'src/records/hooks/useRecords';
import type { DenormalizedBrandSales } from 'src/records/types/DenormalizedBrandSales';
import { type DenormalizedOnPremiseSales } from 'src/records/types/DenormalizedOnPremiseSales';
import {
  denormalizeBrandLine,
  denormalizeOnPremisesSalesDataUtil,
} from 'src/records/utils/denormalizers';
import { BrandLineTypes } from 'src/shared';
import { swapBrandLineDialogActions } from 'src/store/features/swapBrandLineDialog/slice';
import { swapDistributorDialogActions } from 'src/store/features/swapDistributorDialog/slice';
import { type Transaction, useSyncContext } from 'src/sync';
import { TransactionType } from 'src/sync/types';
import type { Entity } from 'src/types/Entity';
import { type BrandLinesEntity } from 'src/types/entity/BrandLines';
import { type BrandSalesEntity } from 'src/types/entity/BrandSales';
import { type CategoriesEntity } from 'src/types/entity/Categories';
import { type DistributorsEntity } from 'src/types/entity/Distributors';
import { type PriceBandsEntity } from 'src/types/entity/PriceBands';
import { useGridViewState, useTable, useTitle, useViews } from 'src/views/hooks';
import { useColumns } from 'src/views/hooks/useColumns';
import { useEnsureForecastAndOtherExist } from 'src/views/hooks/useEnsureForecastAndOtherExist';
import { useGridViewLevelState } from 'src/views/hooks/useGridViewLevelState';
import {
  absoluteChange,
  compoundAnnualGrowthRate,
  relativeChange,
  sortUnknownRows,
} from 'src/views/utils';
import { getAffectedBrandLines } from 'src/views/utils/filters/getAffectedBrandLines';
import { excludeForecasts } from 'src/views/utils/forecasting/grid';
import { sendToClipboard } from 'src/views/utils/grid/gridClipboardCopyUtils';
import { pasteFromClipboard } from 'src/views/utils/grid/gridClipboardPasteUtils';
import { SALES_UPDATE_DIRECTLY_ON_AG_GRID } from '../constants/brandSalesTable';
import { onCellValueChangeHandler } from '../utils/onCellValueChangeHandler';
import { fillOperationHandler } from './fillOperationHandler';
import {
  dataSanityCheck,
  getContextMenuItems as createContextMenu,
  recursivelyAssignDenormalizedBrandSaleDataToNode,
} from './helpers';

import 'src/css/grid.css';

const isExternalFilterPresent = () => true;

export const BRAND_LINE = 'brand line';
export const DISTRIBUTOR = 'distributor';

export const BrandSalesTableViewPage = () => {
  const navigate = useNavigate();
  const { market, table } = useTable();
  const { sections, views } = useViews(table);
  const { viewSlug } = useParams<'viewSlug'>();
  const { key } = useDataGridUnmountKey({ market, table, viewSlug });

  const dataGridApiRef = useRef<unsafe_GridApi<DenormalizedBrandSales>>();

  const { updateEntity, onEntitiesReceived, onTransactionCreated, getEntitiesLookup, getEntities } =
    useSyncContext();

  const getUpdatedBrandSalesFromDataEntities = (data: Record<string, Entity[]>) => {
    const entitiesLookup = getEntitiesLookup();
    const brandLines = getAffectedBrandLines(entitiesLookup, data);

    // get only this market data from provider.
    const brandSalesEntitiesData = data['brandSales'] as unknown as BrandSalesEntity[] | undefined;
    const brandSales =
      brandSalesEntitiesData?.filter(item => item.countryId === market.marketId) ?? [];

    if (brandLines.length > 0) {
      brandLines.forEach(brandLine => {
        const brandSalesEntities = getEntities('brandSales') as unknown as BrandSalesEntity[];
        const brandSalesEntitiesMatchingBrandLine = brandSalesEntities.filter(
          item =>
            item.brandLineGUID === brandLine['brandLineGUID'] && item.countryId === market.marketId
        );
        brandSales.push(...brandSalesEntitiesMatchingBrandLine);
      });
    }

    //if there are any changes on distributors find which sales belong to these distributors.
    const distributors = data['distributors'] ?? [];
    if (distributors.length > 0) {
      distributors.forEach(distributor => {
        const brandSalesEntities = getEntities('brandSales') as unknown as BrandSalesEntity[];
        const brandSalesEntitiesMatchingDistributor = brandSalesEntities.filter(
          item => item.distributorGUID === distributor['distributorGUID']
        );
        brandSales.push(...brandSalesEntitiesMatchingDistributor);
      });
    }

    const uniqueBrandSales = Array.from(new Set(brandSales));

    return uniqueBrandSales;
  };

  const addNewBrandSaleToGridData = (brandSaleData: DenormalizedBrandSales) => {
    // if coming brand sale does not exist, add it to the grid.
    dataGridApiRef.current?.applyTransaction({
      add: [brandSaleData],
    });
    gridData.push(brandSaleData);
  };

  const updatePinnedTopRowDataViaAPI = () => {
    if (viewSlug === 'all') return;

    const gridDataToCalculateFrom: DenormalizedBrandSales[] = [];

    dataGridApiRef.current?.forEachNodeAfterFilter(node => {
      if (node.data) {
        gridDataToCalculateFrom.push(node.data);
      }
    });

    const pinnedTotal = TopTotalsRow(
      market.marketName,
      section?.name ?? '',
      columns,
      gridDataToCalculateFrom
    );

    dataGridApiRef.current?.setPinnedTopRowData(pinnedTotal);
  };

  const updateAgGridData = (data: Record<string, Entity[]>) => {
    const entitiesLookup = getEntitiesLookup();
    const uniqueBrandSales = getUpdatedBrandSalesFromDataEntities(data);
    const rowId = getRowId() as keyof BrandSalesEntity;
    let selectedNode: unsafe_RowNode | undefined;

    uniqueBrandSales.forEach(brandSale => {
      // extra control for skipping sales that does not belong to this market.
      if (brandSale.countryId !== market.marketId) {
        return;
      }

      dataGridApiRef.current?.forEachNode(node => {
        if (node.data && (node.data as Entity)[rowId] === brandSale[rowId]) {
          selectedNode = node;
          return;
        }
      });

      // find the brand line details of the brand sale.
      const brandLine = entitiesLookup['brandLines']?.get(brandSale.brandLineGUID) as
        | BrandLinesEntity
        | undefined;

      if (brandLine) {
        // get denormalized brand sale to match data shape of grid rows.
        const denormalizedBrandLine = denormalizeBrandLine(
          entitiesLookup,
          brandLine as unknown as Entity
        );
        const priceBand = entitiesLookup['priceBands']?.get(
          brandSale.priceBandId as unknown as string
        ) as PriceBandsEntity | undefined;

        const distributor = entitiesLookup['distributors']?.get(brandSale.distributorGUID) as
          | DistributorsEntity
          | undefined;

        const brandSaleData = {
          ...distributor,
          ...denormalizedBrandLine,
          ...priceBand,
          ...brandSale,
        } as DenormalizedBrandSales;

        // check if the incoming brand sale exist in the grid
        if (selectedNode) {
          const gridDataIndexOfNode = gridData.findIndex(
            sale => sale.saleGUID === brandSaleData.saleGUID
          );
          // if incoming brand sale has isDeleted flag, then remove the item from the grid.
          if (brandSale.isDeleted) {
            dataGridApiRef.current?.applyTransaction({ remove: [selectedNode.data] });
            gridData.splice(gridDataIndexOfNode, 1);
          } else {
            /*
              Recursively assign values to the node to prevent cell flashing for object literals (like "price[year]") getting new references,
              and being marked as changed values by AG Grid's change detection.
            */
            recursivelyAssignDenormalizedBrandSaleDataToNode(
              brandSaleData,
              selectedNode.data as DenormalizedBrandSales
            );

            dataGridApiRef.current?.applyTransaction({ update: [selectedNode.data] });
            gridData[gridDataIndexOfNode] = brandSaleData;
          }
        } else if (!brandSale.isDeleted) {
          // if incoming brand sale does not exist, add it to the grid (unless it's a deleted sale)
          addNewBrandSaleToGridData(brandSaleData);
        }
      }

      selectedNode = undefined;
    });
  };

  useEffect(() => {
    // When data received from other tabs or received from delta sync, update AG-GRID data without re-rendering.
    return onEntitiesReceived((data: Record<string, Entity[]>) => {
      updateAgGridData(data);

      const entitiesLookup = getEntitiesLookup();

      if (data['onPremiseSales']?.length) {
        data['onPremiseSales'].forEach(updatedOnPremiseSales => {
          onPremiseSales.push(
            denormalizeOnPremisesSalesDataUtil(
              entitiesLookup,
              updatedOnPremiseSales
            ) as DenormalizedOnPremiseSales
          );
        });
      }
      updatePinnedTopRowDataViaAPI();
    });
  });

  useEffect(() => {
    // After any transaction created, update AG-GRID data without re-rendering.
    return onTransactionCreated((data: { entity: Entity; transaction: Transaction }) => {
      const { entity, transaction } = data;
      // in order to avoid updating brand sale volumes twice when any record is updated.
      if (transaction.actionType !== SALES_UPDATE_DIRECTLY_ON_AG_GRID) {
        updateAgGridData({ [transaction.entityType]: [entity] });
      }

      if (
        transaction.entityType === 'onPremiseSales' &&
        transaction.type === TransactionType.Create
      ) {
        onPremiseSales.push(entity as DenormalizedOnPremiseSales);
      }
      updatePinnedTopRowDataViaAPI();
    });
  });

  const dispatch = useDispatch();
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [selectedNode, setSelectedNode] = useState<Partial<unsafe_RowNode>>();

  const view = useMemo(
    () => views.find(view => view.slug === viewSlug),
    // Don't depend on views. Each time we do useRecords we filter on all views
    // so receive a new object.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewSlug]
  );
  const groupingColumn = useMemo(
    () => (view?.leafField ? { leafField: view.leafField } : {}),
    [view?.leafField]
  );

  const fields = table.fields;

  const section = useMemo(() => {
    return sections.find(section => section.id === view?.sectionId);
    // Don't depend on sections, we get a new object each time with useRecords
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view?.sectionId]);

  useEffect(() => {
    if (!viewSlug) {
      if (!views[0]) throw new Error('No views can be found.');

      navigate(views[0].slug, { replace: true });
    }
  }, [navigate, views, viewSlug]);

  useTitle(`${market.marketName}: ${table.name} - Collector`);

  const gridData = useRecords<DenormalizedBrandSales>(table.source, market.marketId);
  dataSanityCheck(gridData);

  const {
    handleColumnOrderChange,
    handleColumnVisibilityChange,
    handleColumnWidthsChange,
    handlePinnedColumnsChange,
    handleNumbersOfGroupedColumnsToShowChange,
    state,
  } = useGridViewState(table);

  const { handleRowGroupingChange, viewLevelState } = useGridViewLevelState(view);

  const { convertFieldsToColumns } = useColumns();

  const { columns, columnGrouping } = useMemo(
    () =>
      convertFieldsToColumns(fields, {
        periodiseByRows: false,
        lockedView: Boolean(view?.locked),
      }),
    // do not put convertFieldsToColumns in this dependency list as it causes re-rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields, view?.name, view?.locked]
  );

  const pinnedColumns = useMemo(
    () => (state.pinnedColumns ? state.pinnedColumns : undefined),
    [state.pinnedColumns]
  );
  const onPremiseSales = useRecords<DenormalizedOnPremiseSales>('onPremiseSales', market.marketId);

  const { ensureForecastAndOtherExist } = useEnsureForecastAndOtherExist(gridData, onPremiseSales);

  const canUseLOrTFunction = (value: string, functionKey: '=l' | '=t') => {
    const hasFunctionInValue = value.toLowerCase().startsWith(functionKey);

    return hasFunctionInValue && section?.name !== 'All';
  };

  const handleCellEditingStopped = useCallback(
    ({ newValue, node, column, api, oldValue }: unsafe_CellEditingStoppedEvent<Entity>) => {
      const newValueEntered = String(newValue);
      if (canUseLOrTFunction(newValueEntered, '=l')) {
        const updatedAgGridDataPayloads = lockTotals(
          api,
          node,
          column.getColId(),
          newValueEntered,
          oldValue as number | undefined,
          table.primaryField
        );

        if (updatedAgGridDataPayloads) {
          for (const updatedAgGridDataPayload of updatedAgGridDataPayloads) {
            const [entityGuid, payload] = updatedAgGridDataPayload;
            void updateEntity(table.source, entityGuid, payload, SALES_UPDATE_DIRECTLY_ON_AG_GRID);
          }
        }
      }

      if (canUseLOrTFunction(newValueEntered, '=t')) {
        const updatedAgGridDataPayload = setTotals(
          api,
          node,
          column.getColId(),
          newValueEntered,
          table.primaryField
        );

        if (updatedAgGridDataPayload) {
          const [entityGuid, payload] = updatedAgGridDataPayload;
          void updateEntity(table.source, entityGuid, payload, SALES_UPDATE_DIRECTLY_ON_AG_GRID);
        }
      }

      if (column.getColId() === 'priceBandId' && newValue) {
        const { category5Id, isLocal, originId } = node.data as DenormalizedBrandSales;
        ensureForecastAndOtherExist({ category5Id, priceBandId: +newValue, isLocal, originId });
      }

      updatePinnedTopRowDataViaAPI();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table.name, viewSlug]
  );

  const handleCellValueChange = useCallback(
    (newValueParams: unsafe_NewValueParams<DenormalizedBrandSales>) => {
      onCellValueChangeHandler({
        ...newValueParams,
        market,
        table,
        updateEntity,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table.name, viewSlug]
  );

  const formulaFunctions = useMemo(
    () => ({ compoundAnnualGrowthRate, relativeChange, absoluteChange }),
    []
  );

  const postSortRows = useCallback(
    ({ nodes }: unsafe_PostSortRowsParams<Entity>) => {
      view?.shouldSortUnknownRows && sortUnknownRows(nodes);
    },
    [view?.shouldSortUnknownRows]
  );

  const doesExternalFilterPass = useCallback(
    (rowNode: unsafe_RowNode<Entity>) => excludeForecasts(rowNode),
    []
  );

  const getRowId = useCallback(() => table.primaryField, [table.primaryField]);

  const categories = useRecords<CategoriesEntity>('categories');

  const getContextMenuItems = useCallback(
    (params: unsafe_GetContextMenuItemsParams<Entity>) => {
      const colName = params.column?.getColDef().headerName?.toLowerCase();

      return createContextMenu({
        params,
        canWriteRecords: table.canWriteRecords,
        canRemoveRecords: table.canRemoveRecords,
        dispatch,
        setDeleteModalVisible,
        setSelectedNode,
        onSwapAction: () => {
          if (colName && [BRAND_LINE, DISTRIBUTOR].includes(colName)) {
            const brandSale = params.node?.data as unknown as DenormalizedBrandSales;
            const actions =
              colName === BRAND_LINE ? swapBrandLineDialogActions : swapDistributorDialogActions;
            dispatch(actions.open(brandSale));
          }
        },
        view,
        categories,
      });
    },
    [dispatch, table, view, categories]
  );

  const handleGridReady = useCallback(
    (params: unsafe_GridOptions) => {
      if (params.api) {
        dataGridApiRef.current = params.api;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [section?.name, viewSlug]
  );

  const isCellEditable = useCallback(
    (params: {
      data: { brandLineTypeId?: BrandLineTypes | undefined };
      colDef: { field?: string };
    }): boolean =>
      !(
        params.colDef.field?.includes('onPremisePercent') &&
        params.data.brandLineTypeId !== BrandLineTypes.OTHER
      ),
    []
  );

  const handleFilterChange = useCallback(
    () => {
      updatePinnedTopRowDataViaAPI();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewSlug]
  );

  const getRowClass = useCallback((params: unsafe_RowClassParams) => {
    if (params.node.group) {
      return `row-group-${params.node.level}`;
    }
    return '';
  }, []);

  const handleClearAllFilters = useCallback(() => {
    dataGridApiRef.current?.setFilterModel({});
  }, []);

  const handleFillOperation = useCallback(
    (params: FillOperationParams) => {
      const distributors = getEntities('distributors');
      return fillOperationHandler(params, distributors, updateEntity);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!viewSlug) return <p>Loading.</p>;

  if (!view) return <p>View not found.</p>;

  return (
    <>
      <Navbar bg="light" expand="sm" id="viewBar" className="py-0">
        <Nav>
          <ColumnVisibilityMenu
            columnOrder={state.columnOrder}
            pinnedColumns={pinnedColumns}
            allTableFields={fields}
            columnVisibility={state.columnVisibility}
            numbersOfGroupedColumnsToShow={state.numbersOfGroupedColumnsToShow}
            onColumnOrderChange={handleColumnOrderChange}
            onColumnVisibilityChange={handleColumnVisibilityChange}
            onColumnPinnedChange={handlePinnedColumnsChange}
            onNumbersOfGroupedColumnsToShowChange={handleNumbersOfGroupedColumnsToShowChange}
            enableNumbersOfGroupedColumnsToShowFeature
            disableChangingVisibility={!table.canChangeColumnVisibility}
            disableChangingOrder={!table.canChangeColumnOrder}
          />
          <FilterMenu onClearAllFilters={handleClearAllFilters} />
          <GroupingMenu
            columns={fields}
            disabled={!table.canChangeRowGrouping || (view.locked ?? false)}
            rowGrouping={view.locked ? view.rowGrouping : viewLevelState.rowGrouping}
            onRowGroupingChange={handleRowGroupingChange}
          />
          <ExportReportTrigger apiRef={dataGridApiRef} sheets />
        </Nav>
        {isFeatureFlagSet('grid-search') && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Box>
              <GridSearch
                dataGridApiRef={dataGridApiRef}
                searchableFields={[
                  { property: 'brandLineDisplayName', column: 'brandLineDisplayName' },
                  { property: 'distributorName', column: 'distributorName' },
                  { property: 'ownerName', column: 'ownerName' },
                  { property: 'userDetail1', column: 'userDetail1' },
                ]}
              />
            </Box>
          </Box>
        )}
      </Navbar>

      <Container id="viewContainer">
        {views.length > 1 && (
          <StyledSidebar sidebarOpen data-testid="sidebar">
            <SidebarHeader sidebarOpen>
              <SidebarTitle>Options</SidebarTitle>
            </SidebarHeader>
            <SidebarBody sidebarOpen>
              {sections.length > 0 && (
                <ViewSectionSwitcher currentSection={section} currentView={view} />
              )}
              <ViewSwitcher currentSection={section} currentView={view} />
              <AddBrandLineModal />

              {deleteModalVisible && (
                <DeleteSaleModal
                  node={selectedNode}
                  countryName={market.marketName}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  brandLineDisplayName={selectedNode?.data?.brandLineDisplayName as string}
                  closeModal={() => setDeleteModalVisible(false)}
                />
              )}
            </SidebarBody>
          </StyledSidebar>
        )}
        <Content>
          <DataGrid<Entity>
            debug
            key={key}
            aggregation={view.aggregation}
            canChangeColumnOrder={table.canChangeColumnOrder}
            canChangeColumnVisibility={false}
            canChangeRowGrouping={table.canChangeRowGrouping ? !view.locked : false}
            columnGrouping={columnGrouping}
            columnOrder={state.columnOrder}
            columns={columns}
            columnVisibility={state.columnVisibility}
            numbersOfGroupedColumnsToShow={state.numbersOfGroupedColumnsToShow}
            columnWidths={state.columnWidths}
            filter={view.filter ?? []}
            filterIsViewDefined
            formulaFunctions={formulaFunctions}
            getRowId={getRowId}
            groupingColumn={groupingColumn}
            onCellValueChange={handleCellValueChange}
            onCellEditingStopped={handleCellEditingStopped}
            onColumnOrderChange={handleColumnOrderChange}
            onColumnVisibilityChange={handleColumnVisibilityChange}
            onColumnWidthChange={handleColumnWidthsChange}
            onPinnedColumnsChange={handlePinnedColumnsChange}
            onRowGroupingChange={handleRowGroupingChange}
            onFilterChange={handleFilterChange}
            onGridReady={handleGridReady}
            isCellEditable={isCellEditable}
            pinnedColumns={pinnedColumns}
            getRowClass={getRowClass}
            rows={gridData}
            rowGrouping={view.locked ? view.rowGrouping : viewLevelState.rowGrouping}
            sort={view.sort}
            postSortRows={postSortRows}
            getContextMenuItems={getContextMenuItems}
            isExternalFilterPresent={isExternalFilterPresent}
            doesExternalFilterPass={doesExternalFilterPass}
            sendToClipboard={sendToClipboard}
            processDataFromClipboard={pasteFromClipboard}
            enterMovesDownAfterEdit
            enterMovesDown
            enableFillHandle={table.canWriteRecords}
            {...(table.canWriteRecords && { fillOperation: handleFillOperation })}
          />
        </Content>
      </Container>

      <ModalDialog />

      <SwapBrandLineDialog />

      <SwapDistributorDialog />
    </>
  );
};
