import type { MutableRefObject } from 'react';

import type { DataGridProps } from '../../DataGrid';
import type { ColDef, ColGroupDef, GridOptions, Row } from '../../types';
import { createColumnGroupDef, isColGroupDef } from '../../utils';
import { useEffectAfterFirstRender, useGridLogger, useInitialiseState } from '../utils';

export const useColumnGroupingFeature = <R extends Row>(
  gridRef: MutableRefObject<GridOptions<R>>,
  props: Pick<DataGridProps<R>, 'columnGrouping' | 'columns' | 'numbersOfGroupedColumnsToShow'>
) => {
  const logger = useGridLogger(gridRef, 'useColumnGroupingFeature');

  const { columnGrouping = [], columns, numbersOfGroupedColumnsToShow } = props;

  useInitialiseState(() => {
    if (columnGrouping.length === 0) return;

    columnGrouping.forEach(group => {
      if (!gridRef.current.columnDefs) {
        return;
      }

      const colGroupDef = createColumnGroupDef<R>(group);

      const leafField = group.children[0]?.field;
      const indexToInsert = gridRef.current.columnDefs.findIndex(
        col => !isColGroupDef<R>(col) && col.field === leafField
      );

      group.children.forEach(leafColumn => {
        const colDef = gridRef.current.columnDefs?.splice(
          gridRef.current.columnDefs.findIndex(
            col => !isColGroupDef<R>(col) && col.field === leafColumn.field
          ),
          1
        )[0];

        if (colDef && !isColGroupDef<R>(colDef)) {
          colGroupDef.children.push(colDef);
        }
      });

      if (Number(numbersOfGroupedColumnsToShow?.[colGroupDef.groupId]) > 0) {
        colGroupDef.children.forEach((colDef, index) => {
          if (
            index <
            colGroupDef.children.length -
              Number(numbersOfGroupedColumnsToShow?.[colGroupDef.groupId])
          ) {
            colDef.columnGroupShow = 'open';
          } else {
            delete colDef.columnGroupShow;
          }
        });
      }

      gridRef.current.columnDefs.splice(indexToInsert, 0, colGroupDef);
    });
  });

  useEffectAfterFirstRender(() => {
    logger.log('Setting column grouping model');

    if (columnGrouping.length === 0) return;

    const currentColDefs = (gridRef.current.api?.getColumnDefs() ?? []) as (
      | ColDef<R>
      | ColGroupDef<R>
    )[];

    const colDefs = currentColDefs.reduce<(ColDef<R> | ColGroupDef<R>)[]>((acc, colDef) => {
      if (isColGroupDef<R>(colDef)) {
        acc.push(...colDef.children);
      } else {
        acc.push(colDef);
      }
      return acc;
    }, []);

    columnGrouping.forEach(group => {
      const colGroupDef = createColumnGroupDef<R>(group);

      group.children.forEach(leafColumn => {
        const colDef = colDefs.splice(
          colDefs.findIndex(col => !isColGroupDef<R>(col) && col.field === leafColumn.field),
          1
        )[0];

        if (colDef && !isColGroupDef<R>(colDef)) {
          colGroupDef.children.push(colDef);
        }
      });

      colDefs.push(colGroupDef);

      if (Number(numbersOfGroupedColumnsToShow?.[colGroupDef.groupId]) > 0) {
        colGroupDef.children.forEach((colDef, index) => {
          if (
            index <
            colGroupDef.children.length -
              Number(numbersOfGroupedColumnsToShow?.[colGroupDef.groupId])
          ) {
            colDef.columnGroupShow = 'open';
          } else {
            delete colDef.columnGroupShow;
          }
        });
      }
    });
    gridRef.current.api?.setColumnDefs(colDefs);
  }, [columns, columnGrouping, JSON.stringify(numbersOfGroupedColumnsToShow)]);
};
