import { useCallback, useMemo } from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import {
  type ColumnGroupingModel,
  DataGrid,
  type unsafe_NewValueParams,
} from 'src/components/DataGrid';
import { useMarket } from 'src/markets';
import { getBooleanValueFromString, isStringifiedBooleanValue } from 'src/utils/string';
import { getColumnsWithSuffixedForecastYearHeaders } from 'src/views/utils/forecasting/grid';
import { useRecords } from '../../records/hooks/useRecords';
import { useSyncContext } from '../../sync';
import type { Entity } from '../../types/Entity';
import { useTable, useTables } from '../../views/hooks';
import { useColumns } from '../../views/hooks/useColumns';
import type { Field } from '../../views/types';
import { CheckHeader } from '../components/CheckHeader';
import type { TriggerValidationCallback, ValidationCheck, ValidationCheckError } from '../types';

type CheckErrorsGridRow = Record<string, string | number | null | boolean>;

const StyledAlert = styled.div`
  padding: 5px 10px;
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
`;

const convertGridDataToCheckErrorEntity = (data: Record<string, unknown>): Entity => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const dynamicFields: Record<string, unknown> = JSON.parse(String(data['errorDetails'] ?? '{}'));
  const updatedEntity = { ...data };
  Object.keys(dynamicFields).forEach(field => {
    dynamicFields[field] = data[field];
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete updatedEntity[field];
  });
  updatedEntity['errorDetails'] = JSON.stringify(dynamicFields);
  return updatedEntity;
};

interface Props {
  check: ValidationCheck;
  onValidate: TriggerValidationCallback;
}

export const CheckErrorDetails = ({ check, onValidate }: Props) => {
  const {
    market: { marketId },
  } = useTable();
  const { updateEntity } = useSyncContext();
  const { market } = useMarket();
  const tables = useTables(market);

  const { convertFieldsToColumns } = useColumns();
  const entityData = useRecords(check.entityType, marketId);

  const checkErrors = (useRecords('checkErrors', marketId) as unknown as ValidationCheckError[])
    .filter(({ checkId }) => check.checkId === checkId)
    .map(checkError => {
      const table = tables.find(item => item.source === check.entityType);
      const item = table
        ? entityData.find(item => item[table.primaryField] === checkError.entityGUID)
        : {};

      return {
        ...checkError,
        ...item,
        ...JSON.parse(checkError.errorDetails ?? '{}'),
      } as CheckErrorsGridRow;
    });

  // Object gets new reference every render, causing "Controlling 'onCellValueChange'" exception when refreshing the page.
  const fields = useMemo(
    () => JSON.parse(String(check.colDefs ?? '[]')) as Field[],
    [check.colDefs]
  );

  let checkColumnGrouping = JSON.parse(
    String(check.colDefsGrouping ?? '[]')
  ) as ColumnGroupingModel;

  const { columns, columnGrouping } = useMemo(() => {
    const columnsAndColumnGrouping = convertFieldsToColumns(fields, {
      periodiseByRows: false,
      lockedView: true,
    });

    return {
      ...columnsAndColumnGrouping,
      columns: getColumnsWithSuffixedForecastYearHeaders(columnsAndColumnGrouping.columns),
    };
  }, [fields, convertFieldsToColumns]);

  checkColumnGrouping = columnGrouping.concat(checkColumnGrouping);

  const handleCellValueChange = useCallback(
    ({ column, data, newValue }: unsafe_NewValueParams<CheckErrorsGridRow>) => {
      const { entityGUID, entityType, errorDetailId } = data as {
        entityType: string;
        entityGUID: string;
        errorDetailId: string;
      };

      const splitedColId = column.getColId().split('.');

      // We need fields since they contain periodise prop of fields
      // where column.getColDef() returns a Column type which doesn't include it.
      const { periodise, field } =
        fields.find(fieldDef => fieldDef.field === splitedColId[0]) ?? {};
      // For periodised column, we take the year from the respective row

      const payloadValue = isStringifiedBooleanValue(newValue)
        ? getBooleanValueFromString(newValue)
        : (newValue as unknown);

      if (field) {
        let payload = {
          [field]: payloadValue,
        };

        if (periodise && splitedColId[1]) {
          payload = {
            [field]: { [splitedColId[1]]: payloadValue },
          };
        }

        void updateEntity(entityType, entityGUID, payload).then(async () => {
          /*
          @TODO Saving checkError directly to indexdb goes against the principal
          of always showing what's on the server and making sure that all
          researchers see the same data (to keep everything consistent).
          We do this here because when we fix something in checkError grid, the
          change is saved in transaction against the sales line, and we need to
          give the researchers feedback to show in the grid their changes were
          saved, for example when they moves to another view and back.
          Another reason it's ok is that the checkError entities are generated
          on the server when we run validation checks, are never updated back to
          the server by the collector, and will be completely replaced by new
          data from the server when validation is run.
          Future changes (comments on erros etc) might change that and in that
          case, if checkError becomes an entity that updates the server, this
          save should be removed and another way should be implemented.

          A GitHub issue was created to keep track of this:
          https://github.com/theiwsr/collector-web/issues/254
        */

          await updateEntity(
            'checkErrors',
            errorDetailId,
            convertGridDataToCheckErrorEntity({ ...data, [field]: payloadValue }),
            'CheckErrorsUpdate',
            true /* Only update locally, don't generate a transaction */
          );
        });
      }
    },
    // Don't include 'updateEntity' - causes "Controlling 'onCellValueChange'" exception when refreshing the page.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields]
  );

  return (
    <>
      <CheckHeader check={check} onValidate={onValidate} />
      {!['In Progress', 'NotRun'].includes(check.checkStatus) && checkErrors.length > 0 && (
        <>
          {check.isUnfixable && check.unfixableMessage && (
            <StyledAlert>
              <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
              {check.unfixableMessage}
            </StyledAlert>
          )}
          {/*
            Adding checkSlug as key to make sure that the grid component is
            rendered from scratch when navigating to a new view, otherwise we
            will throw when onCellValueChange callback is changed on a re-render.
          */}
          <DataGrid<CheckErrorsGridRow>
            key={check.checkSlug}
            columns={columns}
            canChangeColumnVisibility={false}
            columnGrouping={checkColumnGrouping}
            rows={checkErrors}
            onCellValueChange={handleCellValueChange}
            enterMovesDownAfterEdit
            enterMovesDown
          />
        </>
      )}
    </>
  );
};
