import { type MutableRefObject } from 'react';
import Button from '@mui/material/Button';

import type { unsafe_GridApi, unsafe_RowNode } from 'src/components/DataGrid';
import { ApprovalStatus } from 'src/components/Dialogs/ReviewBrandLineDialog';
import { type DenormalizedBrandLine } from 'src/records/types/DenormalizedBrandLine';

interface Props {
  dataGridApiRef: MutableRefObject<unsafe_GridApi | undefined>;
  isVisible: boolean;
  isDisabled: boolean;
  approverCountries?: number[] | undefined;
}
export const JumpToNextApprovalButton = ({
  dataGridApiRef,
  isVisible,
  isDisabled,
  approverCountries,
}: Props) => {
  if (!isVisible) return null;

  const handleClick = () => {
    const rowIndexesWithPendingApprovalStatus: number[] = [];
    dataGridApiRef.current?.forEachNode(rowNode => {
      const row = rowNode as unsafe_RowNode<DenormalizedBrandLine>;
      if (row.data?.approvalStatus === ApprovalStatus.PENDING) {
        if (approverCountries && !approverCountries.includes(row.data.createdIn)) {
          return;
        }
        if (rowNode.rowIndex !== null) {
          rowIndexesWithPendingApprovalStatus.push(rowNode.rowIndex);
        }
      }
    });

    const rowIndexToJumpTo = Math.min(...rowIndexesWithPendingApprovalStatus);

    dataGridApiRef.current?.ensureIndexVisible(rowIndexToJumpTo, 'middle');
    dataGridApiRef.current?.ensureColumnVisible('approvalStatus');

    dataGridApiRef.current?.setGetRowStyle(params =>
      params.node.rowIndex === rowIndexToJumpTo ? { backgroundColor: 'lavender' } : {}
    );

    dataGridApiRef.current?.setFocusedCell(rowIndexToJumpTo, 'approvalStatus');
    setTimeout(
      () => dataGridApiRef.current?.setFocusedCell(rowIndexToJumpTo, 'approvalStatus'),
      300
    );
  };

  return (
    <Button variant="outlined" color="primary" disabled={isDisabled} onClick={handleClick}>
      Jump to next approval
    </Button>
  );
};
