import { type MutableRefObject, useCallback } from 'react';
import type { ColumnMovedEvent, ColumnState } from '@ag-grid-community/core';

import type { DataGridProps } from '../../DataGrid';
import type { ColumnOrderModel, GridOptions, Row } from '../../types';
import { isColGroupDef } from '../../utils';
import { useEffectAfterFirstRender, useGridLogger, useInitialiseState } from '../utils';

export const useColumnOrderingFeature = <R extends Row>(
  gridRef: MutableRefObject<GridOptions<R>>,
  props: Pick<
    DataGridProps<R>,
    'columnGrouping' | 'columnOrder' | 'columns' | 'onColumnOrderChange'
  >
) => {
  const logger = useGridLogger(gridRef, 'useColumnOrderingFeature');

  const { columnGrouping = [], columnOrder = [], columns, onColumnOrderChange } = props;

  const primaryColumnId = columns.find(col => col.primary)?.field;

  const handleColumnOrderChange = useCallback(
    (e: ColumnMovedEvent) => {
      const orderModel: ColumnOrderModel = [];
      const processedFields: Record<string, boolean> = {};

      e.columnApi
        .getAllGridColumns()
        .filter(col => !col.isPinned())
        .forEach(col => {
          let field: string;

          const parent = col.getOriginalParent();
          if (parent && Object.keys(parent.getColGroupDef() ?? {}).length > 0) {
            field = parent.getGroupId();
          } else {
            field = col.getId();
          }

          if (processedFields[field]) return;
          orderModel.push(field);
          processedFields[field] = true;
        });

      if (e.type === 'columnMoved' && e.column?.getPinned() === 'left' && primaryColumnId) {
        const columnState = [...e.columnApi.getColumnState()];

        const indexOfPrimaryColumn = columnState.findIndex(col => col.colId === primaryColumnId);

        if (indexOfPrimaryColumn > -1) {
          columnState.slice(indexOfPrimaryColumn, 1);
          columnState.unshift({ colId: primaryColumnId });

          e.columnApi.applyColumnState({
            state: columnState,
            applyOrder: true,
          });
        }
      }

      onColumnOrderChange?.(orderModel);
    },
    [onColumnOrderChange, primaryColumnId]
  );

  useInitialiseState(() => {
    if (columnOrder.length > 0) {
      const orderedColDefs: GridOptions<R>['columnDefs'] = [];
      const processedFields: Record<string, boolean> = {};

      columnOrder.forEach(field => {
        if (processedFields[field]) return;

        const colDef = gridRef.current.columnDefs?.find(col =>
          isColGroupDef<R>(col) ? col.groupId === field : col.field === field
        );
        if (colDef) {
          orderedColDefs.push(colDef);
          processedFields[field] = true;
        }
      });

      orderedColDefs.push(
        ...(gridRef.current.columnDefs ?? []).filter(col => {
          return !processedFields[isColGroupDef<R>(col) ? col.groupId : col.field];
        })
      );

      gridRef.current.columnDefs = orderedColDefs;
    }

    gridRef.current.onColumnMoved = handleColumnOrderChange;
  });

  useEffectAfterFirstRender(() => {
    logger.log('Updating column order');

    const orderCriteria: ColumnState[] = [];
    const processedFields: Record<string, boolean> = {};

    columnOrder.forEach(field => {
      if (processedFields[field]) return;

      const group = columnGrouping.find(group => group.groupId === field);
      if (group) {
        group.children.forEach(leafColumn => {
          orderCriteria.push({
            colId: leafColumn.field,
          });
        });
      } else {
        orderCriteria.push({
          colId: field,
        });
      }

      processedFields[field] = true;
    });

    gridRef.current.columnApi?.applyColumnState({
      state: orderCriteria,
      applyOrder: true,
    });
  }, [columns, columnGrouping, columnOrder]);

  useEffectAfterFirstRender(() => {
    throw new TypeError('Controlling `onColumnOrderChange` is not supported.');
  }, [handleColumnOrderChange]);
};
