import { type MutableRefObject, useRef } from 'react';
import { Logger } from '@ag-grid-community/core';

import type { GridOptions, Row } from '../../types';

export const useGridLogger = <R extends Row>(
  gridRef: MutableRefObject<GridOptions<R>>,
  name: string
): Logger => {
  const logger = useRef<Logger | null>(null);

  if (logger.current) {
    return logger.current;
  }

  const newLogger = new Logger(name, () => gridRef.current.debug ?? false);
  logger.current = newLogger;

  return newLogger;
};
