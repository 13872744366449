import { type MutableRefObject, useCallback, useState } from 'react';
import type {
  ColumnRowGroupChangedEvent,
  ColumnState,
  GetMainMenuItemsParams,
} from '@ag-grid-community/core';

import type { DataGridProps } from '../../DataGrid';
import type { ColDef, ColGroupDef, GridOptions, Row } from '../../types';
import { isColGroupDef } from '../../utils';
import { useEffectAfterFirstRender, useGridLogger, useInitialiseState } from '../utils';

export const useRowGroupingFeature = <R extends Row>(
  gridRef: MutableRefObject<GridOptions<R>>,
  props: Pick<
    DataGridProps<R>,
    | 'canChangeRowGrouping'
    | 'columns'
    | 'groupingColumn'
    | 'isGroupExpandedByDefault'
    | 'onRowGroupingChange'
    | 'rowGrouping'
  >
) => {
  const logger = useGridLogger(gridRef, 'useRowGroupingFeature');

  const {
    canChangeRowGrouping = true,
    columns,
    groupingColumn = {},
    isGroupExpandedByDefault,
    onRowGroupingChange,
    rowGrouping = [],
  } = props;

  const getMainMenuItems = useCallback(
    (params: GetMainMenuItemsParams) => {
      if (!canChangeRowGrouping) {
        const excludeItems = ['rowUnGroup', 'rowGroup', 'valueAggSubMenu'];

        return params.defaultItems.filter(opt => !excludeItems.includes(opt));
      }
      return params.defaultItems;
    },
    [canChangeRowGrouping]
  );

  const handleRowGroupingChange = useCallback(
    (e: ColumnRowGroupChangedEvent) => {
      onRowGroupingChange?.(e.columnApi.getRowGroupColumns().map(col => col.getId()));
    },
    [onRowGroupingChange]
  );

  const [currentLeafField, setCurrentLeafField] = useState<string | undefined>(
    groupingColumn.leafField
  );

  useInitialiseState(() => {
    gridRef.current.onColumnRowGroupChanged = handleRowGroupingChange;

    if (rowGrouping.length === 0) return;

    let leafColDef = gridRef.current.columnDefs?.find(
      col => !isColGroupDef<R>(col) && col.field === groupingColumn.leafField
    ) as ColDef<R> | undefined;

    const primaryColumn = columns.find(col => col.primary);

    if (primaryColumn) {
      leafColDef = gridRef.current.columnDefs?.find(
        col => !isColGroupDef<R>(col) && col.field === primaryColumn.field
      ) as ColDef<R> | undefined;
    }

    if (leafColDef) {
      leafColDef.cellRenderer = 'agGroupCellRenderer';
      leafColDef.cellRendererParams = {
        suppressCount: true,
        suppressPadding: true,
      };
      leafColDef.showRowGroup = true;
    }

    rowGrouping.forEach((field, index) => {
      const colDef = gridRef.current.columnDefs?.find(
        col => !isColGroupDef<R>(col) && col.field === field
      ) as ColDef<R> | undefined;
      if (colDef && colDef.enableRowGroup === true) {
        colDef.rowGroupIndex = index;
      }
    });

    if (isGroupExpandedByDefault) {
      gridRef.current.isGroupOpenByDefault = isGroupExpandedByDefault;
    } else {
      delete gridRef.current.isGroupOpenByDefault;
    }

    // Used to remove menu items when grouping shouldn't be changed
    gridRef.current.getMainMenuItems = getMainMenuItems;
  });

  useEffectAfterFirstRender(() => {
    logger.log('Updating leaf field');

    const primaryColumn = columns.find(col => col.primary);

    const colDefs = (gridRef.current.api?.getColumnDefs() ?? []) as (ColDef<R> | ColGroupDef<R>)[];
    colDefs.map(colDef => {
      if (isColGroupDef<R>(colDef)) return;

      const newColDef = colDef;

      if (colDef.field === currentLeafField || colDef.field === primaryColumn?.field) {
        delete newColDef.cellRenderer;
        delete newColDef.cellRendererParams;
        delete newColDef.showRowGroup;
      }

      if (
        (colDef.field === primaryColumn?.field || colDef.field === groupingColumn.leafField) &&
        rowGrouping.length !== 0
      ) {
        newColDef.cellRenderer = 'agGroupCellRenderer';
        newColDef.cellRendererParams = {
          suppressCount: true,
          suppressPadding: true,
        };
        newColDef.showRowGroup = true;
      }

      return newColDef;
    });

    setCurrentLeafField(groupingColumn.leafField);
    gridRef.current.api?.setColumnDefs(colDefs);
  }, [columns, groupingColumn, rowGrouping, canChangeRowGrouping]);

  useEffectAfterFirstRender(() => {
    logger.log('Setting row grouping model');

    const groupingCriteria: ColumnState[] = [];

    rowGrouping.forEach((field, index) => {
      const column = columns.find(col => col.field === field);
      if (column && column.groupable === true) {
        groupingCriteria.push({
          colId: field,
          rowGroupIndex: index,
        });
      }
    });

    gridRef.current.columnApi?.applyColumnState({
      state: groupingCriteria,
      defaultState: {
        rowGroupIndex: null,
      },
    });
  }, [columns, rowGrouping]);

  useEffectAfterFirstRender(() => {
    throw new TypeError('Controlling `onRowGroupingChange` is not supported.');
  }, [handleRowGroupingChange]);

  useEffectAfterFirstRender(() => {
    throw new TypeError('Controlling `isGroupExpandedByDefault` is not supported.');
  }, [isGroupExpandedByDefault]);
};
