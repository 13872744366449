import { config } from 'src/config';

export const ACTIVE_FEATURE_FLAGS = ['external-data', 'command-palette', 'grid-search'] as const;

export const FEATURES_ENABLED_ON_PREVIEW_ENV: FeatureFlags = [];

export type FeatureFlag = (typeof ACTIVE_FEATURE_FLAGS)[number];
type FeatureFlags = FeatureFlag[];

export const getFeatureFlag = (flag: FeatureFlag) => {
  try {
    return localStorage.getItem(flag);
  } catch (err) {
    console.error(err);
  }

  return null;
};

export const isFeatureFlagSet = (flag: FeatureFlag) => {
  if (config.isPreview && FEATURES_ENABLED_ON_PREVIEW_ENV.includes(flag)) {
    return true;
  }

  return getFeatureFlag(flag) === 'true';
};

export const isFeatureBehindFlag = (flag: FeatureFlag) => {
  return ACTIVE_FEATURE_FLAGS.includes(flag);
};
