import { useMemo, useReducer } from 'react';
import { Col, Form } from 'react-bootstrap';
import { DialogContent } from '@mui/material';
import { lensProp, set } from 'ramda';

import CategoryDropdown from 'src/components/BrandSales/AddBrandLine/CategoryDropdown';
import createBrandLineName from 'src/components/BrandSales/AddBrandLine/CreateBrandLine/helpers/createBrandLineName';
import getFormFieldProp from 'src/components/BrandSales/AddBrandLine/CreateBrandLine/helpers/getFormFieldProp';
import type {
  CreateBrandLineRequestPayload,
  NewItem,
} from 'src/components/BrandSales/AddBrandLine/CreateBrandLine/types';
import CreateBrandLineAgeInYearsSection from 'src/components/BrandSales/AddBrandLine/CreateBrandLineAgeInYearsSection';
import CreateBrandLineAgeStyleSection from 'src/components/BrandSales/AddBrandLine/CreateBrandLineAgeStyleSection';
import CreateBrandLineAlcoholicStrengthSection from 'src/components/BrandSales/AddBrandLine/CreateBrandLineAlcoholicStrengthSection';
import CreateBrandLineBleSection from 'src/components/BrandSales/AddBrandLine/CreateBrandLineBleSection';
import CreateBrandLineBrandOwnerSection from 'src/components/BrandSales/AddBrandLine/CreateBrandLineBrandOwnerSection';
import CreateBrandLineCraftSection from 'src/components/BrandSales/AddBrandLine/CreateBrandLineCraftSection';
import CreateBrandLineDisplayNameSection from 'src/components/BrandSales/AddBrandLine/CreateBrandLineDisplayNameSection';
import CreateBrandLineFlavouredSection from 'src/components/BrandSales/AddBrandLine/CreateBrandLineFlavouredSection';
import CreateBrandLineMaltRegionSection from 'src/components/BrandSales/AddBrandLine/CreateBrandLineMaltRegionSection';
import CreateBrandLineOriginSection from 'src/components/BrandSales/AddBrandLine/CreateBrandLineOriginSection';
import CreateBrandLineParentBrandSection from 'src/components/BrandSales/AddBrandLine/CreateBrandLineParentBrandSection';
import CreateBrandLineTypeSection from 'src/components/BrandSales/AddBrandLine/CreateBrandLineTypeSection';
import { Heading, Label } from 'src/components/BrandSales/AddBrandLine/styledComponents';
import { RecordDialog } from 'src/components/RecordDialog';
import { useRecords } from 'src/records/hooks/useRecords';
import type { Brand, BrandApi, BrandLine, BrandOwner, BrandOwnerApi, Origin } from 'src/shared';
import { useAppDispatch, useAppSelector } from 'src/store';
import { createBrandLineDialogActions } from 'src/store/features/createBrandLineDialog/slice';
import { useSyncContext } from 'src/sync';
import type { Entity } from 'src/types/Entity';
import type { AgeStylesEntity } from 'src/types/entity/AgeStyles';
import type { AlcoholicStrengthsEntity } from 'src/types/entity/AlcoholicStrengths';
import type { BrandLinesExtensionEntity } from 'src/types/entity/BrandLineExtensions';
import type { MaltRegionsEntity } from 'src/types/entity/MaltRegions';
import { newGUID } from 'src/utils/appUtils';
import { buildCategoryHandler } from 'src/utils/createBrandLineFormHandlers';
import {
  createBrandLineReducer,
  type CreateBrandLineState,
  initialState,
} from 'src/utils/createBrandLineReducer';
import { isNil, prop as getProp } from 'src/utils/funcUtils';
import { hasInvalidCharacter } from 'src/utils/gridUtils';
import type { FlatCategoryItem } from 'src/views/types';

const GLOBAL_MARKET_ID = 383;

const CreateBrandLineDialogComponent = () => {
  const { createEntity } = useSyncContext();
  const dispatch = useAppDispatch();

  const allBrandLines = useRecords<BrandLine>('brandLines');
  const brands = useRecords<Brand>('brands');
  const origins = useRecords<Origin>('origins');
  const owners = useRecords<BrandOwner>('owners');
  const categories = useRecords<FlatCategoryItem>('categories');
  const brandLineExtensions = useRecords<BrandLinesExtensionEntity>('brandLineExtensions');
  const alcoholicStrengths = useRecords<AlcoholicStrengthsEntity>('alcoholicStrengths');
  const ageStyles = useRecords<AgeStylesEntity>('ageStyles');
  const maltRegions = useRecords<MaltRegionsEntity>('maltRegions');

  const findOrigin = (originName: string): Origin => {
    const matchedOrigin = origins.find(_ => originName === _.originName);
    if (!matchedOrigin) throw new Error(`Cannot find Origin ${originName}`);

    return matchedOrigin;
  };

  const formInit = (): CreateBrandLineState => {
    return initialState;
  };

  const [formState, dispatchFormAction] = useReducer(createBrandLineReducer, null, formInit);

  const parentBrandOptions = useMemo(() => {
    return brands.map((brand: Brand) => ({
      value: brand.brandGUID,
      label: brand.brandName,
      search: brand.brandName.toLowerCase(),
    }));
  }, [brands]);

  const ownerOptions = useMemo(() => {
    return owners.map((owner: BrandOwner) => ({
      value: owner.ownerGUID,
      label: owner.ownerName,
      search: owner.ownerName.toLowerCase(),
    }));
  }, [owners]);

  const validateForm = () => {
    let valid = true;
    let newMessages = formState.errorMessages;

    const validateLengthAndCharacters = (
      value: string | undefined,
      property: string,
      allowSpecialCharacters: boolean
    ) => {
      const getLengthMessage = (length: number) => `Too long: ${length} characters - maximum 50`;

      if (!value) {
        newMessages = set(lensProp(property), 'Value required', newMessages);
        valid = false;
      } else if (value.length > 50) {
        newMessages = set(
          lensProp(property),
          getLengthMessage(formState.displayName.length),
          newMessages
        );
        valid = false;
      } else if (!allowSpecialCharacters && hasInvalidCharacter(value)) {
        newMessages = set(lensProp(property), 'Contains invalid characters', newMessages);
        valid = false;
      } else {
        newMessages = set(lensProp(property), undefined, newMessages);
      }
    };

    validateLengthAndCharacters(formState.displayName, 'displayName', true);
    validateLengthAndCharacters(
      getFormFieldProp(formState, 'parentBrand', 'label'),
      'parentBrand',
      false
    );
    validateLengthAndCharacters(
      getFormFieldProp(formState, 'brandOwner', 'label'),
      'brandOwner',
      false
    );

    if (formState.isNewParentBrand && !formState.isNewParentBrandConfirmed) {
      valid = false;
      newMessages = set(lensProp('checkboxConfirmation'), 'Confirmation is required', newMessages);
    } else {
      newMessages = set(lensProp('checkboxConfirmation'), undefined, newMessages);
    }

    if (!formState.origin) {
      newMessages = set(lensProp('origin'), 'Value required', newMessages);
      valid = false;
    } else {
      newMessages = set(lensProp('origin'), undefined, newMessages);
    }

    if (isNil(getProp('ageStyle', formState))) {
      newMessages = set(lensProp('ageStyle'), 'Value required', newMessages);
      valid = false;
    } else {
      newMessages = set(lensProp('ageStyle'), undefined, newMessages);
    }

    if (formState.maltRegionVisible && isNil(getProp('maltRegion', formState))) {
      newMessages = set(lensProp('maltRegion'), 'Value required', newMessages);
      valid = false;
    } else {
      newMessages = set(lensProp('maltRegion'), undefined, newMessages);
    }

    if (
      valid &&
      allBrandLines.filter(
        b => b.brandLineName === getFormFieldProp(formState, 'brandLine', 'label')
      ).length > 0
    ) {
      valid = false;
      newMessages = set(lensProp('brandLine'), 'Brand line exists', newMessages);
    }

    dispatchFormAction({
      type: 'update',
      payload: {
        errorMessages: !valid ? newMessages : {},
      },
    });

    return valid;
  };

  const createNewParentBrand = () => {
    const payload: BrandApi = {
      brandGUID: getFormFieldProp(formState, 'parentBrand', 'value'),
      brandName: getFormFieldProp(formState, 'parentBrand', 'label'),
      ownerGUID: getFormFieldProp(formState, 'brandOwner', 'value'),
      brandDisplayName: getFormFieldProp(formState, 'parentBrand', 'label'),
      countryId: GLOBAL_MARKET_ID,
      createdIn: GLOBAL_MARKET_ID,
    };
    void createEntity('brands', payload);
  };

  const createNewBrandOwner = () => {
    const payload: BrandOwnerApi = {
      ownerGUID: getFormFieldProp(formState, 'brandOwner', 'value'),
      ownerName: getFormFieldProp(formState, 'brandOwner', 'label'),
      createdIn: GLOBAL_MARKET_ID,
    };
    void createEntity('owners', payload);
  };

  const handleCreateBrandLine = () => {
    const valid = validateForm();

    const brandLineLabel = createBrandLineName({
      categories,
      category5Id: formState.category?.id ?? 0,
      displayName: formState.displayName,
      originName: formState.origin,
    });

    if (!valid || !brandLineLabel) return;

    if ((formState.brandOwner as NewItem).isNew) {
      (formState.brandOwner as NewItem).value = newGUID();
      createNewBrandOwner();
    }

    if ((formState.parentBrand as NewItem).isNew) {
      (formState.parentBrand as NewItem).value = newGUID();
      createNewParentBrand();
    }

    const brandLineToAdd: CreateBrandLineRequestPayload = {
      brandLineGUID: newGUID(),
      brandLineName: brandLineLabel,
      brandLineDisplayName: formState.displayName,
      brandLineTypeId: formState.brandLineTypeId.id,
      brandGUID: getFormFieldProp(formState, 'parentBrand', 'value'),
      category5Id: formState.category?.id ?? 0,
      originId: Number(findOrigin(formState.origin).originId),
      ageId: formState.ageStyle.id,
      ageInYears: formState.ageInYears,
      alcoholicStrengthId: formState.alcoholicStrength.id,
      bleId: formState.ble.id,
      isCraft: formState.craft,
      maltRegionId: formState.maltRegion.id,
      isFlavoured: formState.flavoured,
      createdIn: GLOBAL_MARKET_ID,
    };

    void createEntity('brandLines', brandLineToAdd as unknown as Entity);
    handleClose();
  };

  const handleClose = () => {
    dispatchFormAction({ type: 'reset' });
    dispatch(createBrandLineDialogActions.close());
  };

  return (
    <RecordDialog
      maxWidth="lg"
      open
      onClose={handleClose}
      onSubmit={handleCreateBrandLine}
      submitButtonText="Create"
      title="Create new brand line"
    >
      <DialogContent
        dividers
        data-testid="step_one_create_brandline_modal_body"
        sx={{ height: '60vh' }}
      >
        <Heading>Step 1. New brand details</Heading>
        <Form.Row>
          <Col sm={4}>
            <Form.Group>
              <Label>Category</Label>
              <CategoryDropdown
                categories={categories}
                handleSelectCategory={buildCategoryHandler(
                  dispatchFormAction,
                  origins,
                  brandLineExtensions,
                  alcoholicStrengths,
                  ageStyles,
                  maltRegions
                )}
                selected={formState.category}
                id="create_brandline_category_dropdown"
              />
            </Form.Group>
          </Col>
          <Col sm={4}>
            <CreateBrandLineOriginSection
              formState={formState}
              dispatchFormAction={dispatchFormAction}
              origins={origins}
            />
          </Col>
          <Col sm={4} />
          <Col sm={4}>
            <CreateBrandLineDisplayNameSection
              formState={formState}
              dispatchFormAction={dispatchFormAction}
            />
          </Col>
          <Col sm={4}>
            <CreateBrandLineTypeSection
              formState={formState}
              dispatchFormAction={dispatchFormAction}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm={4}>
            <CreateBrandLineParentBrandSection
              formState={formState}
              dispatchFormAction={dispatchFormAction}
              parentBrandOptions={parentBrandOptions}
              ownerOptions={ownerOptions}
              brands={brands}
            />
          </Col>
          <Col sm={4}>
            <CreateBrandLineBrandOwnerSection
              formState={formState}
              dispatchFormAction={dispatchFormAction}
              ownerOptions={ownerOptions}
              owners={owners}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm={4}>
            <CreateBrandLineAgeStyleSection
              formState={formState}
              dispatchFormAction={dispatchFormAction}
            />
          </Col>
          <Col sm={4}>
            <CreateBrandLineFlavouredSection
              formState={formState}
              dispatchFormAction={dispatchFormAction}
            />
          </Col>
          <Col sm={4}>
            <CreateBrandLineAlcoholicStrengthSection
              formState={formState}
              dispatchFormAction={dispatchFormAction}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          {formState.ageInYearsVisible && (
            <Col sm={4}>
              <CreateBrandLineAgeInYearsSection
                formState={formState}
                dispatchFormAction={dispatchFormAction}
              />
            </Col>
          )}
          {formState.maltRegionVisible && (
            <Col sm={4}>
              <CreateBrandLineMaltRegionSection
                formState={formState}
                dispatchFormAction={dispatchFormAction}
                maltRegions={maltRegions}
              />
            </Col>
          )}
          {formState.craftVisible && (
            <Col sm={4}>
              <CreateBrandLineCraftSection
                formState={formState}
                dispatchFormAction={dispatchFormAction}
              />
            </Col>
          )}
          {formState.bleVisible && (
            <Col sm={4}>
              <CreateBrandLineBleSection
                formState={formState}
                dispatchFormAction={dispatchFormAction}
              />
            </Col>
          )}
        </Form.Row>
      </DialogContent>
    </RecordDialog>
  );
};

export const CreateBrandLineDialog = () => {
  const isOpen = useAppSelector(state => state.createBrandLineDialog.isOpen);
  return isOpen ? <CreateBrandLineDialogComponent /> : null;
};
