import type { unsafe_GetContextMenuItemsParams, unsafe_MenuItemDef } from 'src/components/DataGrid';

export enum ContextMenuActionType {
  ADD,
  DELETE,
  EDIT,
  SWAP,
  COPYCELL,
}

export interface GridContextMenuItem extends unsafe_MenuItemDef {
  actionType: ContextMenuActionType;
  isMainMenuItem?: boolean;
}

const getContextMenuIcon = (actionType: ContextMenuActionType) => {
  switch (actionType) {
    case ContextMenuActionType.ADD:
      return `<span unselectable="on" role="presentation">
                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 4C11.4477 4 11 4.44772 11 5V11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H11V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H13V5C13 4.44772 12.5523 4 12 4Z" fill="currentColor" /></svg>
              </span>`;
    case ContextMenuActionType.DELETE:
      return `<span style="color: red" unselectable="on" role="presentation">
                <svg fill="#db0000" width="18" height="18" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="#db0000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M5.755,20.283,4,8H20L18.245,20.283A2,2,0,0,1,16.265,22H7.735A2,2,0,0,1,5.755,20.283ZM21,4H16V3a1,1,0,0,0-1-1H9A1,1,0,0,0,8,3V4H3A1,1,0,0,0,3,6H21a1,1,0,0,0,0-2Z"></path></g></svg>
              </span>`;
    case ContextMenuActionType.EDIT:
      return `<span unselectable="on" role="presentation">
                <svg  width="18" height="18"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M18 10L21 7L17 3L14 6M18 10L8 20H4V16L14 6M18 10L14 6" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
              </span>`;
    case ContextMenuActionType.SWAP:
      return `<span unselectable="on" role="presentation">
                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M21 7.5L8 7.5M21 7.5L16.6667 3M21 7.5L16.6667 12" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M4 16.5L17 16.5M4 16.5L8.33333 21M4 16.5L8.33333 12" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
              </span>`;
    case ContextMenuActionType.COPYCELL:
      return `<span unselectable="on" role="presentation">
                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.8284 12L16.2426 13.4142L19.071 10.5858C20.6331 9.02365 20.6331 6.49099 19.071 4.9289C17.509 3.3668 14.9763 3.3668 13.4142 4.9289L10.5858 7.75732L12 9.17154L14.8284 6.34311C15.6095 5.56206 16.8758 5.56206 17.6568 6.34311C18.4379 7.12416 18.4379 8.39049 17.6568 9.17154L14.8284 12Z" fill="currentColor" /><path d="M12 14.8285L13.4142 16.2427L10.5858 19.0711C9.02372 20.6332 6.49106 20.6332 4.92896 19.0711C3.36686 17.509 3.36686 14.9764 4.92896 13.4143L7.75739 10.5858L9.1716 12L6.34317 14.8285C5.56212 15.6095 5.56212 16.8758 6.34317 17.6569C7.12422 18.4379 8.39055 18.4379 9.1716 17.6569L12 14.8285Z" fill="currentColor" /><path d="M14.8285 10.5857C15.219 10.1952 15.219 9.56199 14.8285 9.17147C14.4379 8.78094 13.8048 8.78094 13.4142 9.17147L9.1716 13.4141C8.78107 13.8046 8.78107 14.4378 9.1716 14.8283C9.56212 15.2188 10.1953 15.2188 10.5858 14.8283L14.8285 10.5857Z" fill="currentColor" /></svg>
              </span>`;
  }
};

export const createContextMenu = (
  canRemoveRecords: boolean | undefined,
  params: unsafe_GetContextMenuItemsParams,
  gridContextMenuItems: GridContextMenuItem[]
) => {
  const tempGridContextItem: GridContextMenuItem[] = [];

  if (params.defaultItems && params.defaultItems.length > 0) {
    for (const item of params.defaultItems) {
      if (!['copyWithHeaders', 'copyWithGroupHeaders', 'export'].includes(item)) {
        tempGridContextItem.push(item as unknown as GridContextMenuItem);
      }
    }
  }

  let deleteContextItem: GridContextMenuItem | undefined = undefined;
  let mainContextItem: GridContextMenuItem | undefined = undefined;

  const tempSubGridContextItem: GridContextMenuItem[] = [];
  for (const gridItem of gridContextMenuItems) {
    if (gridItem.actionType === ContextMenuActionType.DELETE && canRemoveRecords) {
      deleteContextItem = {
        ...gridItem,
        icon: getContextMenuIcon(gridItem.actionType),
        cssClasses: ['removeContextMenuItemFont'],
      };
    } else if (gridItem.isMainMenuItem) {
      mainContextItem = {
        ...gridItem,
        icon: getContextMenuIcon(gridItem.actionType),
      };
    } else {
      tempSubGridContextItem.push({
        ...gridItem,
        icon: getContextMenuIcon(gridItem.actionType),
      });
    }
  }

  if (mainContextItem) {
    tempGridContextItem.push(mainContextItem);
    tempGridContextItem.push('separator' as unknown as GridContextMenuItem);
  }

  if (tempSubGridContextItem.length > 0) {
    tempGridContextItem.push(...tempSubGridContextItem);
    tempGridContextItem.push('separator' as unknown as GridContextMenuItem);
  }
  tempGridContextItem.push({
    name: 'Copy cell URL',
    actionType: ContextMenuActionType.COPYCELL,
    action: () => {
      // @TODO - According to ticket this function will be implemented later, so action is just writing a log to console.
      console.log('Copy cell URL is called!');
    },
    icon: getContextMenuIcon(ContextMenuActionType.COPYCELL),
    tooltip: 'Copy cell URL',
  });

  if (deleteContextItem) {
    tempGridContextItem.push('separator' as unknown as GridContextMenuItem);
    tempGridContextItem.push(deleteContextItem);
  }

  return tempGridContextItem;
};
