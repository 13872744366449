import type { AgeStylesEntity } from '../types/entity/AgeStyles';
import type { AlcoholicStrengthsEntity } from '../types/entity/AlcoholicStrengths';
import type { BrandLinesExtensionEntity } from '../types/entity/BrandLineExtensions';
import type { MaltRegionsEntity } from '../types/entity/MaltRegions';

export interface OptionValue {
  value: string;
  label: string;
}

export interface NumericOption {
  id: number;
  text: string;
}

export interface BooleanOption {
  value: boolean;
  text: string;
}

export const emptyOption: NumericOption = {
  id: 0,
  text: '-',
};

export const mapNumericToValue = (numericInput: NumericOption): OptionValue => ({
  label: numericInput.text,
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  value: numericInput?.id?.toString(),
});
export const mapNumericsToValues = (a: NumericOption[]): OptionValue[] => a.map(mapNumericToValue);

export const mapValueToNumeric = (v: OptionValue | undefined | null): NumericOption => {
  if (v === undefined || v === null) return emptyOption;
  return { text: v.label, id: +v.value };
};

export const mapMaltRegionValues = (maltRegions: MaltRegionsEntity[]): NumericOption[] =>
  maltRegions
    .filter(item => item.maltRegionId !== 0)
    .map(item => ({
      id: item.maltRegionId,
      text: item.maltRegionName,
    }));

export const mapMaltRegionValue = (
  maltRegionOption: NumericOption,
  maltRegions: MaltRegionsEntity[]
) =>
  maltRegions
    .filter((item: MaltRegionsEntity) => item.maltRegionName === maltRegionOption.text)
    .map(elementMalt => ({
      label: elementMalt.maltRegionName,

      value: elementMalt.maltRegionId.toString(),
    }))[0];

export const getAgeStyleText = (id: number, ageStyles: AgeStylesEntity[]): string =>
  ageStyles.find(item => item.ageId === id)?.ageStatement ?? '';

export const getAgeStyleId = (ageStatement: string, ageStyles: AgeStylesEntity[]): number =>
  ageStyles.find(item => item.ageStatement === ageStatement)?.ageId ?? 0;

export const mapAgeStyleValue = (
  rulesAgeStyle: string,
  ageStyles: AgeStylesEntity[]
): NumericOption => ({
  id: getAgeStyleId(rulesAgeStyle, ageStyles),
  text: rulesAgeStyle,
});

export const mapAgeStyleValues = (
  rulesAgeStyleOptions: string[],
  ageStyles: AgeStylesEntity[]
): NumericOption[] =>
  rulesAgeStyleOptions.map(rulesAgeStyleOption => mapAgeStyleValue(rulesAgeStyleOption, ageStyles));

export const findBooleanOption = (options: BooleanOption[], v: boolean) =>
  options.find(o => o.value === v)?.text ?? '';

export const mapAlcoholicStrengthValue = (
  alcoholicStrengthBand: string,
  alcoholicStrengths: AlcoholicStrengthsEntity[]
): NumericOption => {
  const alcoholicStrengthId =
    alcoholicStrengths.find(item => item.alcoholicStrengthBand === alcoholicStrengthBand)
      ?.alcoholicStrengthId ?? 0;

  return {
    id: alcoholicStrengthId,
    text: alcoholicStrengthBand,
  };
};

export const mapAlcoholicStrengthValues = (
  rulesAlcoholicStrengthsOptions: string[],
  alcoholicStrengths: AlcoholicStrengthsEntity[]
): NumericOption[] =>
  rulesAlcoholicStrengthsOptions.map(alcoholicStrength =>
    mapAlcoholicStrengthValue(alcoholicStrength, alcoholicStrengths)
  );

const getBleId = (
  ruleBleOption: string,
  brandLineExtensions: BrandLinesExtensionEntity[]
): number => brandLineExtensions.find(item => item.bleName === ruleBleOption)?.bleId ?? 0;

export const mapBleValue = (
  ruleBleOption: string,
  brandLineExtensions: BrandLinesExtensionEntity[]
) => ({
  id: getBleId(ruleBleOption, brandLineExtensions),
  text: ruleBleOption,
});

export const mapBleValues = (
  rulesBleOptions: string[],
  brandLineExtensions: BrandLinesExtensionEntity[]
): NumericOption[] =>
  rulesBleOptions.map(ruleBleOption => mapBleValue(ruleBleOption, brandLineExtensions));
