import { clone, mergeDeepRight } from 'ramda';
import { v4 as uuidv4 } from 'uuid';

import { store } from 'src/store';
import type { Entity } from '../../types/Entity';
import { logError, logSyncingError } from '../../utils/logging';
import { entityConfiguration } from '../config';
import { createObservable } from '../functions';
import {
  doDeleteRequest,
  doFetchRequest,
  doPatchRequest,
  doPostRequest,
  doPutRequest,
} from '../functions/request';
import {
  type ApiAuditData,
  type ApiResponse,
  BroadcastMessageType,
  type CreateTransaction,
  type DeleteTransaction,
  type EntityReview,
  HttpStatusCode,
  QueueManagerState,
  type ReviewTransaction,
  type Transaction,
  type TransactionLog,
  TransactionSyncState,
  TransactionType,
  type UpdateTransaction,
} from '../types';
import type { BroadcastManager } from './broadcast-manager';
import type { CachingManager } from './caching-manager';
import type { LocalDbManager } from './localdb-manager';

export class QueueManager {
  /**
   * An observable to monitor changes in the number of transactions.
   */
  public readonly onTransactionCountChange = createObservable<number>();

  /**
   * An observable to provide new receiving entities, data can be provided by created transaction on local or created transaction on other tabs.
   */
  public readonly onEntitiesReceived = createObservable<Record<string, Entity[]>>();

  /**
   * An observable to provide created transactions.
   */
  public readonly onTransactionCreated = createObservable<{
    entity: Entity;
    transaction: Transaction;
  }>();

  /**
   * An observable to monitor changes in the status of the transaction queue.
   */
  public readonly onQueueManagerStateChange = createObservable<QueueManagerState>();

  public status: QueueManagerState = QueueManagerState.Success;

  // Keep transaction logs for...
  private readonly TRANSACTION_LOG_DAYS = 30;

  private transactions: Transaction[] = [];

  constructor(
    private readonly localDb: LocalDbManager,
    private readonly cachingManager: CachingManager,
    private readonly broadcastManager: BroadcastManager
  ) {
    this.broadcastManager.onQueueManagerStateChange.subscribe((status: QueueManagerState) => {
      this.setQueueManagerState(status);
    });

    this.broadcastManager.onTransactionCreated.subscribe((transaction: Transaction) => {
      this.transactions.push(transaction);
      const entity = this.getEntity(transaction);
      if (entity) {
        this.cachingManager.cacheEntities({ [transaction.entityType]: [entity] });
        this.onEntitiesReceived.notify({ [transaction.entityType]: [entity] });
      }
      this.onTransactionCountChange.notify(this.transactions.length);
    });

    this.broadcastManager.onTransactionRemoved.subscribe((transaction: Transaction) => {
      if (transaction.id) {
        void this.deleteTransaction(transaction.id);
        this.onTransactionCountChange.notify(this.transactions.length);
      }
    });
  }

  /**
   * Cache all indexed db transactions to the cached.
   */
  async cacheExistingTransactions() {
    this.transactions = await this.localDb.getAllTransactions();
  }

  /**
   * Clear the queue.
   */
  clear() {
    this.transactions = [];
  }

  /**
   * Returns orders order by createdAt date.
   */
  getTransactions() {
    return this.transactions.sort(
      (objA, objB) => objA.createdAt.getTime() - objB.createdAt.getTime()
    );
  }

  /**
   * Returns failed transactions count
   */
  getFailedTransactionsCount() {
    return this.transactions.filter(item => item.status === TransactionSyncState.Failed).length;
  }

  /**
   * Add a create entity transaction to the queue.
   */
  async createEntity(entityType: string, entity: Entity, actionType?: string) {
    const tx: CreateTransaction = {
      entityType,
      actionType,
      payload: entity,
      status: TransactionSyncState.Queued,
      type: TransactionType.Create,
      createdAt: new Date(),
      transactionId: uuidv4(),
    };

    await this.enqueueTransaction(tx);

    return this.syncTransactions();
  }

  /**
   * Add an update entity transaction to the queue.
   */
  async updateEntity(
    entityType: string,
    entityGUID: string,
    changes: Partial<Entity>,
    actionType?: string
  ) {
    const tx: UpdateTransaction = {
      entityGUID,
      entityType,
      actionType,
      payload: changes,
      status: TransactionSyncState.Queued,
      type: TransactionType.Update,
      createdAt: new Date(),
      transactionId: uuidv4(),
    };

    await this.enqueueTransaction(tx);
    return this.syncTransactions();
  }

  /**
   * Add a review entity transaction to the queue.
   */
  async reviewEntity(
    entityType: string,
    entityGUID: string,
    review: EntityReview,
    actionType?: string
  ) {
    const tx: ReviewTransaction = {
      entityGUID,
      entityType,
      actionType,
      payload: review,
      status: TransactionSyncState.Queued,
      type: TransactionType.Review,
      createdAt: new Date(),
      transactionId: uuidv4(),
    };

    await this.enqueueTransaction(tx);
    return this.syncTransactions();
  }

  /**
   * Add a delete entity transaction to the queue.
   */
  async deleteEntity(entityType: string, entityGUID: string, actionType?: string) {
    const tx: DeleteTransaction = {
      entityGUID,
      entityType,
      actionType,
      status: TransactionSyncState.Queued,
      type: TransactionType.Delete,
      createdAt: new Date(),
      transactionId: uuidv4(),
    };

    await this.enqueueTransaction(tx);
    return this.syncTransactions();
  }

  /**
   * Sync transactions in the queue to the backend server.
   */
  async syncTransactions() {
    // check if navigator online and if there is sync transaction in progress on other tab
    // if yes, skipping doing deltasyncing.
    if (!navigator.onLine || this.status === QueueManagerState.InProgress) {
      return;
    }

    // this navigator lock is not required because of the control above
    // however kept for the browsers which are not supporting channel broadcast!
    await navigator.locks.request('transactionQueue-pushTransactions', async () => {
      const transactions = await this.localDb.getAllTransactions();
      this.setQueueManagerState(QueueManagerState.InProgress);
      this.broadcastQueueManagerStateChange();

      if (transactions.length > 0) {
        for (const transaction of transactions) {
          const transactionSyncState = await this.syncTransactionDispatcher(transaction);

          if (transactionSyncState !== TransactionSyncState.Success) {
            await this.localDb.putTransaction({
              ...transaction,
              status: transactionSyncState,
            });

            this.setQueueManagerState(
              transactionSyncState === TransactionSyncState.TimedOut
                ? QueueManagerState.TimedOut
                : QueueManagerState.Failed
            );
            break;
          }

          if (!transaction.id) {
            throw Error('Transaction id not found!');
          }
          await this.deleteTransaction(transaction.id);

          this.broadcastTransactionRemoved(transaction);
          this.setQueueManagerState(QueueManagerState.Success);
          this.setQueueManagerState(QueueManagerState.InProgress);
        }
      }

      if (this.status === QueueManagerState.InProgress) {
        this.setQueueManagerState(QueueManagerState.Success);
      }
      this.broadcastQueueManagerStateChange();
    });
  }

  /**
   * Check transaction logs against the api.
   */

  async checkTransactionLog() {
    // check if navigator is online and sync transaction is in progress on
    // another tab. if so, skip doing log check.
    if (!navigator.onLine || this.status === QueueManagerState.InProgress) {
      return;
    }
    // this navigator lock is not required because of the control above
    // however kept for the browsers which don't support channel broadcast!
    await navigator.locks.request('transactionLog-verify', async () => {
      const transactionLogs = await this.localDb.getAllUnverifiedTransactionLogs();
      this.setQueueManagerState(QueueManagerState.InProgress);
      this.broadcastQueueManagerStateChange();

      if (transactionLogs.length > 0) {
        const username = store.getState().auth.user?.email;

        const methods = {
          create: 'POST',
          update: 'PATCH',
          review: 'PUT',
          delete: 'DELETE',
        } as const;

        const transactionIdList = transactionLogs.map(tl => tl.transactionId).toString();
        const transactionIdArr = transactionIdList.split(',');

        let fetchResponse: ApiResponse | undefined;

        try {
          fetchResponse = await doFetchRequest('audit', undefined, {
            transactionIds: transactionIdList,
          });
        } catch (err) {
          console.error('Error fetching apiAdit', { err });
        }

        const { data } = fetchResponse as { data: ApiAuditData[] | undefined };

        if (data) {
          for (const auditLog of data) {
            const transactionLog = await this.localDb.getTransactionLog(auditLog.transactionId);

            if (transactionLog) {
              let diffs = 0;
              if (auditLog.transactionId !== transactionLog.transactionId) diffs++;
              if (auditLog.username !== username) diffs++;
              if (auditLog.method !== methods[transactionLog.type]) diffs++;
              if (
                //remove the backslashes
                auditLog.requestBody.replaceAll(String.fromCharCode(92), '') !==
                JSON.stringify(transactionLog.payload)
              )
                diffs++;
              if (!diffs) {
                transactionLog.auditId = Number(auditLog.auditId);
                transactionLog.verifiedDate = new Date();
                await this.localDb.putTransactionLog(transactionLog);
              } else {
                console.error(
                  'Fields from auditLog !== transactionLog for transactionId %s',
                  transactionLog.transactionId
                );
              }

              const arrIndex = transactionIdArr.indexOf(transactionLog.transactionId);
              transactionIdArr.splice(arrIndex, 1);
            }
          }
        }

        // Have all the transactions in the list been accounted for?
        if (transactionIdArr.length) {
          console.error(
            `Audits not found for transactionLog${transactionIdArr.length > 1 ? 's' : ''}`,
            { transactionIdArr }
          );
        }
      }

      if (this.status === QueueManagerState.InProgress) {
        this.setQueueManagerState(QueueManagerState.Success);
      }
      this.broadcastQueueManagerStateChange();
    });
  }

  /**
   * Purge transaction log.
   */
  async purgeTransactionLog() {
    // check if navigator is online and sync transaction is in progress on
    // another tab. if so, skip doing log check.
    if (!navigator.onLine || this.status === QueueManagerState.InProgress) {
      return;
    }
    // this navigator lock is not required because of the control above
    // however kept for the browsers which don't support channel broadcast!
    await navigator.locks.request('transactionLog-purge', async () => {
      const purgeDate = new Date();

      // Once verified, a log is kept for TRANSACTION_LOG_DAYS days...
      purgeDate.setDate(purgeDate.getDate() - (this.TRANSACTION_LOG_DAYS + 1));

      // Therefore, purgeDate is TRANSACTION_LOG_DAYS + 1...
      const transactionLogs =
        await this.localDb.getTransactionLogsVerifiedOnOrBeforeDate(purgeDate);

      if (transactionLogs.length > 0) {
        for (const transactionLog of transactionLogs) {
          // The Error throw should never occur:
          // 1) An id will always be created on the transaction;
          // 2) verifiedDate will never be set unless an auditId is set;
          // 3) The verifiedDate index isn't added to unless verified date is set.
          if (!transactionLog.auditId || !transactionLog.verifiedDate || !transactionLog.id) {
            throw Error(
              `Transaction log for transactionID ${transactionLog.transactionId} has no id, auditId or verifiedDate!`
            );
          }
          await this.localDb.deleteTransactionLog(transactionLog.id);
        }
      }
    });
  }

  /**
   * Add a transaction to the queue.
   */
  private async enqueueTransaction(tx: Transaction) {
    const txCopy = tx;

    txCopy.id = await this.localDb.putTransaction(tx);

    try {
      const txLog: TransactionLog = clone(tx);
      delete txLog.status;
      // Have to set auditId to zero rather than null.
      // If null, wouldn't get included in the auditId index.
      // With verifiedDate index, we don't want any unverified logs being included.
      await this.localDb.putTransactionLog({ ...txLog, auditId: 0, verifiedDate: null });
    } catch (err) {
      logError(`Adding to transactionLog failed-(TID: ${tx.transactionId})`, { ...tx, error: err });
    }

    this.transactions.push(txCopy);
    const entity = this.getEntity(tx);
    if (entity) {
      this.cachingManager.cacheEntities({ [tx.entityType]: [entity] });
      this.onTransactionCreated.notify({ entity, transaction: tx });
    }

    this.onTransactionCountChange.notify(this.transactions.length);
    this.broadcastTransactionCreated(txCopy);
  }

  private async syncTransactionDispatcher(transaction: Transaction) {
    switch (transaction.type) {
      case TransactionType.Create:
        return this.syncCreateTransaction(transaction);
      case TransactionType.Update:
        return this.syncUpdateTransaction(transaction);
      case TransactionType.Review:
        return this.syncReviewTransaction(transaction);
      case TransactionType.Delete:
        return this.syncDeleteTransaction(transaction);
    }
  }

  /**
   * Push create modifier type item to server
   */
  private async syncCreateTransaction(
    entityCreateTransaction: CreateTransaction
  ): Promise<TransactionSyncState> {
    const { entityType, payload, transactionId } = entityCreateTransaction;

    try {
      const postResponse = await doPostRequest({
        path: entityType,
        transactionId,
        requestPayload: payload,
      });

      if (postResponse.status === HttpStatusCode.SuccessCreated) {
        await this.localDb.putEntity(postResponse.data as Entity, entityType);
        this.cachingManager.cacheEntity({ [entityType]: postResponse.data as Entity });
        return TransactionSyncState.Success;
      } else if (postResponse.status === HttpStatusCode.AlreadyReported) {
        const entity = this.getEntity(entityCreateTransaction);

        if (entity) {
          await this.localDb.putEntity(entity, entityType);
          this.cachingManager.cacheEntity({ [entityType]: entity });
        }
        return TransactionSyncState.Success;
      } else if (
        postResponse.status === HttpStatusCode.RequestTimeout ||
        postResponse.status === HttpStatusCode.GatewayTimeout
      ) {
        logSyncingError('Sync Transaction Timeout', transactionId, {
          entityCreateTransaction,
          postResponse,
        });
        return TransactionSyncState.TimedOut;
      }
      logSyncingError('Sync Transaction Failed', transactionId, {
        entityCreateTransaction,
        postResponse,
      });
      return TransactionSyncState.Failed;
    } catch (err) {
      logSyncingError('Sync Transaction Exception', transactionId, {
        entityCreateTransaction,
        err,
      });
      return TransactionSyncState.Failed;
    }
  }

  /**
   * Push update modifier type item to server
   */
  private syncUpdateTransaction = async (entityUpdateTransaction: UpdateTransaction) => {
    const { entityType, payload, entityGUID, transactionId } = entityUpdateTransaction;
    try {
      const patchResponse = await doPatchRequest({
        path: `${entityType}/${entityGUID}`,
        transactionId,
        requestPayload: payload,
      });

      if (patchResponse.status === HttpStatusCode.SuccessOK) {
        await this.localDb.putEntity(patchResponse.data as Entity, entityType);
        this.cachingManager.cacheEntity({ [entityType]: patchResponse.data as Entity });
        return TransactionSyncState.Success;
      } else if (patchResponse.status === HttpStatusCode.AlreadyReported) {
        const entity = this.getEntity(entityUpdateTransaction);

        if (entity) {
          await this.localDb.putEntity(entity, entityType);
          this.cachingManager.cacheEntity({ [entityType]: entity });
        }
        return TransactionSyncState.Success;
      } else if (
        patchResponse.status === HttpStatusCode.RequestTimeout ||
        patchResponse.status === HttpStatusCode.GatewayTimeout
      ) {
        logSyncingError('Sync Transaction Timeout', transactionId, {
          entityUpdateTransaction,
          patchResponse,
        });
        return TransactionSyncState.TimedOut;
      }
      logSyncingError('Sync Transaction Failed', transactionId, {
        entityUpdateTransaction,
        patchResponse,
      });
      return TransactionSyncState.Failed;
    } catch (err) {
      logSyncingError('Sync Transaction Exception', transactionId, {
        entityUpdateTransaction,
        err,
      });
      return TransactionSyncState.Failed;
    }
  };

  /**
   * Push review modifier type item to server
   */
  private async syncReviewTransaction(entityReviewTransaction: ReviewTransaction) {
    const { entityType, payload, entityGUID, transactionId } = entityReviewTransaction;
    try {
      const putResponse = await doPutRequest({
        path: `${entityType}/${entityGUID}/review`,
        transactionId,
        requestPayload: payload,
      });

      if (putResponse.status === HttpStatusCode.SuccessOK) {
        await this.localDb.putEntity(putResponse.data as Entity, entityType);
        this.cachingManager.cacheEntity({ [entityType]: putResponse.data as Entity });
        return TransactionSyncState.Success;
      } else if (putResponse.status === HttpStatusCode.AlreadyReported) {
        const entity = this.getEntity(entityReviewTransaction);

        if (entity) {
          await this.localDb.putEntity(entity, entityType);
          this.cachingManager.cacheEntity({ [entityType]: entity });
        }

        return TransactionSyncState.Success;
      } else if (
        putResponse.status === HttpStatusCode.RequestTimeout ||
        putResponse.status === HttpStatusCode.GatewayTimeout
      ) {
        logSyncingError('Sync Transaction Timeout', transactionId, {
          entityReviewTransaction,
          putResponse,
        });
        return TransactionSyncState.TimedOut;
      }
      logSyncingError('Sync Transaction Failed', transactionId, {
        entityReviewTransaction,
        putResponse,
      });
      return TransactionSyncState.Failed;
    } catch (err) {
      logSyncingError('Sync Transaction Exception', transactionId, {
        entityReviewTransaction,
        err,
      });
      return TransactionSyncState.Failed;
    }
  }

  /**
   * Push delete modifier type item to server
   */
  private async syncDeleteTransaction(entityDeleteTransaction: DeleteTransaction) {
    const { entityType, entityGUID, transactionId } = entityDeleteTransaction;
    try {
      const deleteResponse = await doDeleteRequest({
        path: `${entityType}/${entityGUID}`,
        transactionId,
      });

      const collectorEntity = entityConfiguration.entities[entityType];

      if (
        (deleteResponse.status === HttpStatusCode.SuccessOK ||
          deleteResponse.status === HttpStatusCode.AlreadyReported) &&
        collectorEntity
      ) {
        await this.localDb.putEntity(
          {
            [collectorEntity.keyPath]: entityGUID,
            isDeleted: true,
          } as Entity,
          entityType
        );
        this.cachingManager.cacheEntity({
          [entityType]: {
            [collectorEntity.keyPath]: entityGUID,
            isDeleted: true,
          } as Entity,
        });
        return TransactionSyncState.Success;
      } else if (
        deleteResponse.status === HttpStatusCode.RequestTimeout ||
        deleteResponse.status === HttpStatusCode.GatewayTimeout
      ) {
        logSyncingError('Sync Transaction Timeout', transactionId, {
          entityDeleteTransaction,
          deleteResponse,
        });
        return TransactionSyncState.TimedOut;
      }
      logSyncingError('Sync Transaction Failed', transactionId, {
        entityDeleteTransaction,
        deleteResponse,
      });
      return TransactionSyncState.Failed;
    } catch (err) {
      logSyncingError('Sync Transaction Exception', transactionId, {
        entityDeleteTransaction,
        err,
      });
      return TransactionSyncState.Failed;
    }
  }

  /**
   * A handler for indexedDb subscription which is executed when an entity inserted/updated.
   */
  public getEntity = (transaction: Transaction): Entity | undefined => {
    if (transaction.type === TransactionType.Create) {
      return transaction.payload;
    } else if (transaction.type === TransactionType.Delete) {
      const existingEntity = this.cachingManager.getEntity(
        transaction.entityType,
        transaction.entityGUID
      );
      if (existingEntity) {
        return { ...existingEntity, isDeleted: true };
      }
    } else {
      const existingEntity = this.cachingManager.getEntity(
        transaction.entityType,
        transaction.entityGUID
      );

      if (existingEntity) {
        const clonedEntity = mergeDeepRight(existingEntity, transaction.payload);
        return clonedEntity;
      }
    }
    return undefined;
  };

  /**
   * Notify subscribers when transaction sync state changes.
   */
  private setQueueManagerState(state: QueueManagerState) {
    this.status = state;
    this.onQueueManagerStateChange.notify(state);
  }

  private broadcastQueueManagerStateChange() {
    this.broadcastManager.postMessage({
      type: BroadcastMessageType.QueueManagerStateChange,
      payload: this.status,
    });
  }

  private broadcastTransactionCreated(transaction: Transaction) {
    this.broadcastManager.postMessage({
      type: BroadcastMessageType.TransactionCreated,
      payload: transaction,
    });
  }

  private broadcastTransactionRemoved(transaction: Transaction) {
    this.broadcastManager.postMessage({
      type: BroadcastMessageType.TransactionRemoved,
      payload: transaction,
    });
  }

  private async deleteTransaction(id: string) {
    this.transactions = this.transactions.filter(item => item.id !== id);
    await this.localDb.deleteTransaction(id);
  }
}
