import { OnlineState } from '../types';
import { createObservable } from './observable';

export function createConnectivityDetector() {
  let onlineStatus = OnlineState.Online;
  const onOnlineStatusChange = createObservable<OnlineState>();

  const handleOnlineChange = () => {
    onlineStatus = navigator.onLine ? OnlineState.Online : OnlineState.Offline;
    onOnlineStatusChange.notify(onlineStatus);
  };

  window.addEventListener('offline', handleOnlineChange);
  window.addEventListener('online', handleOnlineChange);

  return {
    get onlineStatus() {
      return onlineStatus;
    },
    get onOnlineStatusChange() {
      return onOnlineStatusChange;
    },
  };
}
