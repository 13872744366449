import { useMemo, useReducer } from 'react';
import { Col, Container, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lensProp, set } from 'ramda';
import { v4 as uuidv4 } from 'uuid';

import ModalType from 'src/components/ModalDialog/ModalType';
import { Button } from 'src/css/styled-components';
import { useMarket } from 'src/markets/hooks/useMarket';
import { useRecords } from 'src/records/hooks/useRecords';
import type { Brand, BrandApi, BrandLine, BrandOwner, BrandOwnerApi, Origin } from 'src/shared';
import { BrandLineStatus } from 'src/shared';
import type { RootState } from 'src/store';
import {
  goToStep1,
  goToStep2,
  selectBrandLine,
  stageNewBrandLine,
  stageNewBrandOwner,
  stageNewParentBrand,
} from 'src/store/features/brandLineAdd/actions';
import { setModalDialogType } from 'src/store/features/modalDialog/actions';
import { useSyncContext } from 'src/sync';
import type { AgeStylesEntity } from 'src/types/entity/AgeStyles';
import type { AlcoholicStrengthsEntity } from 'src/types/entity/AlcoholicStrengths';
import type { BrandLinesExtensionEntity } from 'src/types/entity/BrandLineExtensions';
import type { MaltRegionsEntity } from 'src/types/entity/MaltRegions';
import { newGUID } from 'src/utils/appUtils';
import { buildCategoryHandler } from 'src/utils/createBrandLineFormHandlers';
import { findBooleanOption } from 'src/utils/createBrandLineFormMappers';
import {
  createBrandLineReducer,
  type CreateBrandLineState,
  initialState,
} from 'src/utils/createBrandLineReducer';
import { isNil, prop as getProp } from 'src/utils/funcUtils';
import { hasInvalidCharacter } from 'src/utils/gridUtils';
import type { FlatCategoryItem } from 'src/views/types';
import BrandLineTable from '../BrandLineTable';
import CategoryDropdown from '../CategoryDropdown';
import CreateBrandLineAgeInYearsSection from '../CreateBrandLineAgeInYearsSection';
import CreateBrandLineAgeStyleSection from '../CreateBrandLineAgeStyleSection';
import CreateBrandLineAlcoholicStrengthSection from '../CreateBrandLineAlcoholicStrengthSection';
import CreateBrandLineBleSection from '../CreateBrandLineBleSection';
import CreateBrandLineBrandOwnerSection from '../CreateBrandLineBrandOwnerSection';
import CreateBrandLineCraftSection from '../CreateBrandLineCraftSection';
import CreateBrandLineDisplayNameSection from '../CreateBrandLineDisplayNameSection';
import CreateBrandLineFlavouredSection from '../CreateBrandLineFlavouredSection';
import CreateBrandLineMaltRegionSection from '../CreateBrandLineMaltRegionSection';
import CreateBrandLineOriginSection from '../CreateBrandLineOriginSection';
import CreateBrandLineParentBrandSection from '../CreateBrandLineParentBrandSection';
import CreateBrandLineTypeSection from '../CreateBrandLineTypeSection';
import { Heading, Label } from '../styledComponents';
import createBrandLineName from './helpers/createBrandLineName';
import getFormFieldProp from './helpers/getFormFieldProp';
import type { NewItem, Props } from './types';

const GLOBAL_MARKET_ID = 383;

const CreateBrandLine = ({ closeModal, modalHeight, isCreateBrandLineForm }: Props) => {
  const dispatch = useDispatch();
  const { createEntity } = useSyncContext();

  const state = useSelector((state: RootState) => state);

  const newBrandLines = state.brandLineAdd.create?.brandLines ?? [];
  const newParentBrands = useMemo(() => state.brandLineAdd.create?.parentBrands ?? [], [state]);
  const newBrandOwners = useMemo(() => state.brandLineAdd.create?.brandOwners ?? [], [state]);
  const selected = state.brandLineAdd.step1?.selected ?? [];

  const allBrandLines = useRecords<BrandLine>('brandLines');
  const brands = useRecords<Brand>('brands');
  const origins = useRecords<Origin>('origins');
  const owners = useRecords<BrandOwner>('owners');
  const categories = useRecords<FlatCategoryItem>('categories');
  const brandLineExtensions = useRecords<BrandLinesExtensionEntity>('brandLineExtensions');
  const alcoholicStrengths = useRecords<AlcoholicStrengthsEntity>('alcoholicStrengths');
  const ageStyles = useRecords<AgeStylesEntity>('ageStyles');
  const maltRegions = useRecords<MaltRegionsEntity>('maltRegions');

  const findOrigin = (originName: string): Origin => {
    const matchedOrigin = origins.find(_ => originName === _.originName);
    if (!matchedOrigin) throw new Error(`Cannot find Origin ${originName}`);

    return matchedOrigin;
  };

  const {
    market: { marketId: selectedMarket },
  } = useMarket();

  const formInit = (): CreateBrandLineState => {
    return initialState;
  };

  const [formState, dispatchFormAction] = useReducer(createBrandLineReducer, null, formInit);

  const showSelected = selected.length > 0;
  const disableNext = !showSelected;

  const parentBrandOptions = useMemo(() => {
    const brandsFromDb = brands.map((brand: Brand) => ({
      value: brand.brandGUID,
      label: brand.brandName,
      search: brand.brandName.toLowerCase(),
    }));

    const newList = newParentBrands.map(newParentBrand => ({
      value: newParentBrand.value,
      label: newParentBrand.label,
      search: newParentBrand.label.toLowerCase(),
    }));

    return newList.concat(brandsFromDb);
  }, [brands, newParentBrands]);

  const ownerOptions = useMemo(() => {
    const ownersFromDb = owners.map((owner: BrandOwner) => ({
      value: owner.ownerGUID,
      label: owner.ownerName,
      search: owner.ownerName.toLowerCase(),
    }));
    const newList = newBrandOwners.map(newItem => ({
      value: newItem.value,
      label: newItem.label,
      search: newItem.label.toLowerCase(),
    }));

    return newList.concat(ownersFromDb);
  }, [owners, newBrandOwners]);

  const handleBack = () => {
    dispatch(goToStep1());
  };

  const handleNext = () => {
    dispatch(goToStep2());
  };

  const handleAddNewParentBrand = (uuidNewParentBrand: string, uuidNewBrandOwner: string) => {
    const brandToCreateApi: BrandApi = {
      brandGUID: uuidNewParentBrand,
      brandName: getFormFieldProp(formState, 'parentBrand', 'label'),
      ownerGUID: uuidNewBrandOwner,
      brandDisplayName: getFormFieldProp(formState, 'parentBrand', 'label'),
      countryId: GLOBAL_MARKET_ID,
      createdIn: selectedMarket,
    };

    void createEntity('brands', brandToCreateApi);
  };

  const handleAddNewBrandOwner = (uuid: string) => {
    const ownerToAddApi: BrandOwnerApi = {
      ownerGUID: uuid,
      ownerName: getFormFieldProp(formState, 'brandOwner', 'label'),
      createdIn: selectedMarket,
    };

    void createEntity('owners', ownerToAddApi);
  };

  const handleCreateBrandLines = () => {
    // Loop over each item in the list and check for any brand owners or parents brands that have been created.
    selected.forEach(brandLineToAdd => {
      const { brandGUID, ownerGUID, brandLineGUID } = brandLineToAdd;

      // Newly created brand owner/parent brands will have a GUID format of 'new#<guid>', try to extract the GUID part.
      const createdItemGUIDs = {
        brandOwner: ownerGUID.split('new#')[1],
        parentBrand: brandGUID.split('new#')[1],
        brandLineGUID: brandLineGUID.split('new#')[1],
      };

      const isCreatedBrandOwner = !!createdItemGUIDs.brandOwner;
      // If the brand owner is newly created, use the existing generated GUID. Otherwise use the existing ownerGUID.
      const brandOwnerGUID = isCreatedBrandOwner ? createdItemGUIDs.brandOwner : ownerGUID;

      if (!brandOwnerGUID) {
        throw new Error('Cannot happen - there must be a brandOwnerGuid!');
      }

      if (isCreatedBrandOwner) {
        handleAddNewBrandOwner(brandOwnerGUID);
      }

      const isCreatedParentBrand = !!createdItemGUIDs.parentBrand;
      // If the parent brand is newly created, use the existing GUID. Otherwise use the existing brandGUID.
      const parentBrandGUID = isCreatedParentBrand ? uuidv4() : brandGUID;

      if (isCreatedParentBrand) {
        handleAddNewParentBrand(parentBrandGUID, brandOwnerGUID);
      }

      void createEntity('brandLines', {
        ...brandLineToAdd,
        brandLineGUID: createdItemGUIDs.brandLineGUID,
        brandGUID: parentBrandGUID,
      });
    });
    closeModal();
  };

  const validateForm = () => {
    let valid = true;
    let newMessages = formState.errorMessages;

    const validateLengthAndCharacters = (
      value: string | undefined,
      property: string,
      allowSpecialCharacters: boolean
    ) => {
      const getLengthMessage = (length: number) => `Too long: ${length} characters - maximum 50`;

      if (!value) {
        newMessages = set(lensProp(property), 'Value required', newMessages);
        valid = false;
      } else if (value.length > 50) {
        newMessages = set(
          lensProp(property),
          getLengthMessage(formState.displayName.length),
          newMessages
        );
        valid = false;
      } else if (!allowSpecialCharacters && hasInvalidCharacter(value)) {
        newMessages = set(lensProp(property), 'Contains invalid characters', newMessages);
        valid = false;
      } else {
        newMessages = set(lensProp(property), undefined, newMessages);
      }
    };

    validateLengthAndCharacters(formState.displayName, 'displayName', true);
    validateLengthAndCharacters(
      getFormFieldProp(formState, 'parentBrand', 'label'),
      'parentBrand',
      false
    );
    validateLengthAndCharacters(
      getFormFieldProp(formState, 'brandOwner', 'label'),
      'brandOwner',
      false
    );

    if (formState.isNewParentBrand && !formState.isNewParentBrandConfirmed) {
      valid = false;
      newMessages = set(lensProp('checkboxConfirmation'), 'Confirmation is required', newMessages);
    } else {
      newMessages = set(lensProp('checkboxConfirmation'), undefined, newMessages);
    }

    if (!formState.origin) {
      newMessages = set(lensProp('origin'), 'Value required', newMessages);
      valid = false;
    } else {
      newMessages = set(lensProp('origin'), undefined, newMessages);
    }

    if (isNil(getProp('ageStyle', formState))) {
      newMessages = set(lensProp('ageStyle'), 'Value required', newMessages);
      valid = false;
    } else {
      newMessages = set(lensProp('ageStyle'), undefined, newMessages);
    }

    if (formState.maltRegionVisible && isNil(getProp('maltRegion', formState))) {
      newMessages = set(lensProp('maltRegion'), 'Value required', newMessages);
      valid = false;
    } else {
      newMessages = set(lensProp('maltRegion'), undefined, newMessages);
    }

    if (
      valid &&
      allBrandLines.filter(
        b => b.brandLineName === getFormFieldProp(formState, 'brandLine', 'label')
      ).length > 0
    ) {
      valid = false;
      newMessages = set(lensProp('brandLine'), 'Brand line exists', newMessages);
    }

    dispatchFormAction({
      type: 'update',
      payload: {
        errorMessages: !valid ? newMessages : {},
      },
    });

    return valid;
  };

  const handleAdd = () => {
    const valid = validateForm();

    const brandLineLabel = createBrandLineName({
      categories,
      category5Id: formState.category?.id ?? 0,
      displayName: formState.displayName,
      originName: formState.origin,
    });

    if (valid) {
      const createBrandLine = () => {
        formState.brandLine = { value: newGUID(), label: brandLineLabel };
        if ((formState.parentBrand as NewItem).isNew) {
          if (
            !(newParentBrands as NewItem[]).some(
              item => item.label === (formState.parentBrand as NewItem).label
            )
          ) {
            (formState.parentBrand as NewItem).value = newGUID();
            dispatch(stageNewParentBrand(formState.parentBrand as NewItem));
          }
        }
        if ((formState.brandOwner as NewItem).isNew) {
          if (
            !(newBrandOwners as NewItem[]).some(
              item => item.label === (formState.brandOwner as NewItem).label
            )
          ) {
            (formState.brandOwner as NewItem).value = newGUID();
            dispatch(stageNewBrandOwner(formState.brandOwner as NewItem));
          }
        }

        const newLine: BrandLine = {
          // add new# to indicate to the backend that it needs creating
          brandLineGUID: 'new#'.concat(getFormFieldProp(formState, 'brandLine', 'value')),
          brandLineName: getFormFieldProp(formState, 'brandLine', 'label'),

          brandLineDisplayName: formState.displayName,

          brandGUID: (formState.parentBrand as NewItem).isNew
            ? 'new#'.concat(getFormFieldProp(formState, 'parentBrand', 'value'))
            : getFormFieldProp(formState, 'parentBrand', 'value'),
          brandName: getFormFieldProp(formState, 'parentBrand', 'label'),

          ownerGUID: (formState.brandOwner as NewItem).isNew
            ? 'new#'.concat(getFormFieldProp(formState, 'brandOwner', 'value'))
            : getFormFieldProp(formState, 'brandOwner', 'value'),
          ownerName: getFormFieldProp(formState, 'brandOwner', 'label'),

          originId: Number(findOrigin(formState.origin).originId),
          originName: formState.origin,
          originDisplayOrder: findOrigin(formState.origin).displayOrder,

          brandLineTypeId: formState.brandLineTypeId.id,

          isFlavoured: formState.flavoured,
          flavouredName: findBooleanOption(formState.flavouredOptions, formState.flavoured),

          isCraft: formState.craft,
          craftName: findBooleanOption(formState.craftOptions, formState.craft),

          alcoholicStrengthId: formState.alcoholicStrength.id,
          alcoholicStrengthBand: formState.alcoholicStrength.text,

          bleId: formState.ble.id,
          bleName: formState.ble.text,

          ageId: formState.ageStyle.id,
          ageStatement: formState.ageStyle.text,

          maltRegionId: formState.maltRegion.id,
          maltRegionName: formState.maltRegion.text,

          ageInYears: formState.ageInYears,

          category5Id: formState.category?.id ?? 0,
          category5Name: formState.category?.name ?? '',

          usualPriceBandId: 1,
          createdIn: selectedMarket,
          reportingCategory: false,
          status: BrandLineStatus.PENDING,
        };

        dispatch(selectBrandLine(newLine));
        dispatch(stageNewBrandLine(newLine));
      };

      if (formState.origin === 'International') {
        dispatch(
          setModalDialogType(ModalType.CONFIRMATION, {
            title: 'Confirmation is required',
            body: 'You have selected International as the origin. Are you sure you wish to proceed?',
            confirmationCallback: createBrandLine,
          })
        );
      } else {
        createBrandLine();
      }
    }
  };

  const getSummary = () => ` ${newBrandLines.length} new brand lines,
            ${newParentBrands.length} new parent brands,
            ${newBrandOwners.length} new brand owners`;

  return (
    <>
      <Container>
        <Modal.Body
          style={{
            minHeight: `${modalHeight - 200}px`,
            maxHeight: `${modalHeight - 20}px`,
            display: 'flex',
            flexDirection: 'column',
          }}
          data-testid="step_one_create_brandline_modal_body"
        >
          <Heading>Step 1. New brand details</Heading>
          <Form.Row>
            <Col sm={4}>
              <Form.Group>
                <Label>Category</Label>
                <CategoryDropdown
                  categories={categories}
                  handleSelectCategory={buildCategoryHandler(
                    dispatchFormAction,
                    origins,
                    brandLineExtensions,
                    alcoholicStrengths,
                    ageStyles,
                    maltRegions
                  )}
                  selected={formState.category}
                  id="create_brandline_category_dropdown"
                />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <CreateBrandLineOriginSection
                formState={formState}
                dispatchFormAction={dispatchFormAction}
                origins={origins}
              />
            </Col>
            <Col sm={4} />
            <Col sm={4}>
              <CreateBrandLineDisplayNameSection
                formState={formState}
                dispatchFormAction={dispatchFormAction}
              />
            </Col>
            <Col sm={4}>
              <CreateBrandLineTypeSection
                formState={formState}
                dispatchFormAction={dispatchFormAction}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col sm={4}>
              <CreateBrandLineParentBrandSection
                formState={formState}
                dispatchFormAction={dispatchFormAction}
                parentBrandOptions={parentBrandOptions}
                ownerOptions={ownerOptions}
                brands={brands}
              />
            </Col>
            <Col sm={4}>
              <CreateBrandLineBrandOwnerSection
                formState={formState}
                dispatchFormAction={dispatchFormAction}
                ownerOptions={ownerOptions}
                owners={owners}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col sm={4}>
              <CreateBrandLineAgeStyleSection
                formState={formState}
                dispatchFormAction={dispatchFormAction}
              />
            </Col>
            <Col sm={4}>
              <CreateBrandLineFlavouredSection
                formState={formState}
                dispatchFormAction={dispatchFormAction}
              />
            </Col>
            <Col sm={4}>
              <CreateBrandLineAlcoholicStrengthSection
                formState={formState}
                dispatchFormAction={dispatchFormAction}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            {formState.ageInYearsVisible && (
              <Col sm={4}>
                <CreateBrandLineAgeInYearsSection
                  formState={formState}
                  dispatchFormAction={dispatchFormAction}
                />
              </Col>
            )}
            {formState.maltRegionVisible && (
              <Col sm={4}>
                <CreateBrandLineMaltRegionSection
                  formState={formState}
                  dispatchFormAction={dispatchFormAction}
                  maltRegions={maltRegions}
                />
              </Col>
            )}
            {formState.craftVisible && (
              <Col sm={4}>
                <CreateBrandLineCraftSection
                  formState={formState}
                  dispatchFormAction={dispatchFormAction}
                />
              </Col>
            )}
            {formState.bleVisible && (
              <Col sm={4}>
                <CreateBrandLineBleSection
                  formState={formState}
                  dispatchFormAction={dispatchFormAction}
                />
              </Col>
            )}
          </Form.Row>
          <Form.Row>
            {formState.category && (
              <Button
                type="button"
                variant="primary"
                data-testid="create_brand_line_add_to_list_button"
                onClick={handleAdd}
              >
                <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                Add to list
              </Button>
            )}
          </Form.Row>

          {showSelected && (
            <>
              <Heading>Your list</Heading>
              <div>{getSummary()}</div>
              <BrandLineTable
                brandLines={selected}
                isSelected
                containerStyles={{ maxHeight: '295px' }}
              />
            </>
          )}
        </Modal.Body>
      </Container>
      <Modal.Footer>
        <Button
          type="button"
          data-testid="create_brand_line_cancel_button"
          variant="link"
          onClick={closeModal}
        >
          Cancel
        </Button>

        {!isCreateBrandLineForm && (
          <>
            <Button
              type="button"
              data-testid="create_brand_line_back_button"
              variant="secondary"
              onClick={handleBack}
            >
              Back
            </Button>
            <Button
              type="submit"
              data-testid="create_brand_line_step2_button"
              variant="primary"
              disabled={disableNext}
              onClick={handleNext}
            >
              Step 2: Add brand data
            </Button>
          </>
        )}
        {isCreateBrandLineForm && (
          <Button
            type="submit"
            variant="primary"
            disabled={disableNext}
            data-testid="create_brand_line_create_button"
            onClick={handleCreateBrandLines}
          >
            Create
          </Button>
        )}
      </Modal.Footer>
    </>
  );
};

export default CreateBrandLine;
