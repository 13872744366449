import type { Column, ColumnGroup, ColumnGroupingModel } from 'src/components/DataGrid';
import { COLLECTED_YEAR, DEFAULT_FIRST_YEAR, DEFAULT_NUMBER_OF_YEARS_TO_SHOW } from 'src/constants';
import { useRecords } from 'src/records/hooks/useRecords';
import type { Entity } from 'src/types/Entity';
import type { Field } from '../types';

interface ConvertFieldsToColumnsOptions {
  periodiseByRows?: boolean;
  lockedView?: boolean | undefined;
}

function fieldsToColumns(
  fields: Field[],
  { periodiseByRows = false, lockedView = false }: ConvertFieldsToColumnsOptions = {}
) {
  const columnGrouping: ColumnGroupingModel = [];
  const columns: Column[] = fields.flatMap(fieldElement => {
    const field = fieldElement;
    field.sortable = !lockedView;

    if (field.periodise && !periodiseByRows) {
      const columns: Column[] = [];
      const group: ColumnGroup = {
        children: [],
        description: field.description ?? field.headerName,
        headerName: field.headerName,
        groupId: field.field,
      };

      const {
        firstYear = DEFAULT_FIRST_YEAR,
        forecastYearsToAdd,
        numberOfYearsToShow = DEFAULT_NUMBER_OF_YEARS_TO_SHOW,
        onlyForecastYearsAreEditable,
      } = field.periodiseOptions ?? {};

      let endYear = COLLECTED_YEAR;
      if (forecastYearsToAdd) {
        endYear += forecastYearsToAdd;
      }

      const checkEditableYear = (year: number): boolean => {
        if (onlyForecastYearsAreEditable && forecastYearsToAdd) {
          return year > endYear - forecastYearsToAdd;
        }
        if (!field.editable) return false;
        return true;
      };

      for (let year = firstYear; year <= endYear; year++) {
        columns.push({
          ...field,
          field: `${field.field}.${year}`,
          headerName: String(year),
          editable: checkEditableYear(year),
          pinnable: false,
          moveable: false,
          columnGroupShow: year <= endYear - numberOfYearsToShow ? 'open' : undefined,
        });
        group.children.push({ field: `${field.field}.${year}` });
      }

      columnGrouping.push(group);
      return columns;
    }

    return field;
  });

  return { columns, columnGrouping };
}

const addChoices = (columns: Column[], lookups: Record<string, Entity[]>) =>
  columns.map(col => {
    if (col.type !== 'entity') {
      return col;
    }

    const { entityType, valueField, labelField, orderField } = col.options;

    const categoryColumn = col.field.match(/category(\d)Id/);

    const lookupTable = categoryColumn
      ? lookups[entityType]?.filter(({ level }) => String(level) === categoryColumn[1])
      : lookups[entityType];

    const choices = lookupTable?.map(entity => ({
      value: entity[valueField],
      label: entity[labelField],
      displayOrder: entity[orderField ?? ''] ?? undefined,
    })) as
      | {
          value: string | number;
          label: string;
          displayOrder?: number;
        }[]
      | undefined;

    if (!choices) {
      return col;
    }

    if (choices.every(choice => typeof choice.displayOrder !== 'undefined')) {
      choices.sort(
        ({ displayOrder }, { displayOrder: otherDisplayOrder }) =>
          Number(displayOrder) - Number(otherDisplayOrder)
      );
    } else {
      choices.sort(({ label }, { label: otherLabel }) => label.localeCompare(otherLabel));
    }

    return {
      ...col,
      options: {
        ...col.options,
        choices,
      },
    };
  });

export const useColumns = () => {
  const ageStyles = useRecords('ageStyles');
  const alcoholicStrengths = useRecords('alcoholicStrengths');
  const brandLineExtensions = useRecords('brandLineExtensions');
  const categories = useRecords('categories');
  const maltRegions = useRecords('maltRegions');
  const markets = useRecords('markets');
  const origins = useRecords('origins');
  const priceBands = useRecords('priceBands');
  const wineVarietalOptions = useRecords('wineVarietalOptions');

  const convertFieldsToColumns = (
    fields: Field[],
    options: ConvertFieldsToColumnsOptions = {}
  ): { columns: Column[]; columnGrouping: ColumnGroupingModel } => {
    const { columns, columnGrouping } = fieldsToColumns(fields, options);
    return {
      columns: addChoices(columns, {
        ageStyles,
        alcoholicStrengths,
        brandLineExtensions,
        categories,
        maltRegions,
        markets,
        origins,
        priceBands,
        wineVarietalOptions,
      }) as Column[],
      columnGrouping,
    };
  };

  return {
    convertFieldsToColumns,
  };
};
