import type { DropdownOption } from 'src/components/Dropdown/types';
import type { CategoriesEntity } from 'src/types/entity/Categories';
import type { BrandLine } from '../../../../shared/types';
import type { Entity } from '../../../../types/Entity';

export interface SubcategoriesTreeOption
  extends Pick<DropdownOption, 'value' | 'label' | 'id' | 'isDisabled'> {
  level: number;
  parentId: number;
}

export interface SubcategoriesTreeResult {
  options: SubcategoriesTreeOption[];
  categoriesLevel5: CategoriesEntity[];
}

export const convertNewToOldBrandLines = (
  dataBrandLines: Entity[] | BrandLine[],
  dataOrigins: Entity[],
  dataBrands: Entity[],
  dataOwners: Entity[],
  categoriesLevel5: CategoriesEntity[]
) => {
  const data: BrandLine[] = [];
  if (dataOrigins.length > 0 && dataBrands.length > 0 && categoriesLevel5.length > 0) {
    dataBrandLines.forEach(item => {
      const itemBL = {} as BrandLine;

      const {
        originId,
        brandGUID,
        category1Id,
        category5Id,
        brandLineName,
        brandLineGUID,
        brandLineDisplayName,
      } = item;

      itemBL.originDisplayOrder = 0;
      itemBL.brandLineTypeId = item['brandLineTypeId'] as number;

      (itemBL.isFlavoured = false), (itemBL.flavouredName = '');

      itemBL.alcoholicStrengthId = 0;
      itemBL.alcoholicStrengthBand = '';

      (itemBL.isCraft = false), (itemBL.craftName = '');

      itemBL.bleId = 0;
      itemBL.bleName = '';
      itemBL.ageId = 0;
      itemBL.ageStatement = '';
      itemBL.maltRegionId = 0;
      itemBL.maltRegionName = '';
      itemBL.ageInYears = 0;

      const getCategory5 = categoriesLevel5.find(
        categoriesLevel5Item => category5Id === categoriesLevel5Item.id
      );

      itemBL.category5Name = getCategory5?.name ?? '';
      (itemBL.reportingCategory = false), (itemBL.status = 1);

      const getOrigin = dataOrigins.find(dataOrigin => dataOrigin['originId'] === originId);
      const getBrand = dataBrands.find(dataBrand => dataBrand['brandGUID'] === brandGUID);
      const getOwner = dataOwners.find(
        dataOwner => getBrand?.['ownerGUID'] === dataOwner['ownerGUID']
      );

      itemBL.ownerGUID = getOwner?.['ownerGUID'] as string;
      itemBL.ownerName = getOwner?.['ownerName'] as string;
      itemBL.originName = getOrigin?.['originName'] as string;
      itemBL.brandName = getBrand?.['brandName'] as string;
      itemBL.brandLineName = brandLineName as string;
      itemBL.brandLineGUID = brandLineGUID as string;
      itemBL.category1Id = category1Id as number;
      itemBL.category5Id = category5Id as number;
      itemBL.brandGUID = brandGUID as string;
      itemBL.originId = originId as number;
      itemBL.brandLineDisplayName = brandLineDisplayName as string;
      data.push(itemBL);
    });
  }

  return data;
};

export const createSubcategoriesTree = (inputCategories: CategoriesEntity[]) => {
  if (inputCategories.length === 0) {
    return {
      options: undefined,
      categoriesLevel5: [] as CategoriesEntity[],
    };
  }

  const categoriesLevel1 = inputCategories.filter(item => item.level === 1);
  const categoriesLevel2 = inputCategories.filter(item => item.level === 2);
  const categoriesLevel5 = inputCategories.filter(item => item.level === 5);
  const result: SubcategoriesTreeResult = {
    options: [],
    categoriesLevel5: [],
  };

  categoriesLevel1
    .sort((a, b) => a.displayOrder - b.displayOrder)
    .map(itemCategories1 => {
      result.options.push({
        label: itemCategories1.name,
        value: itemCategories1.legacyId,
        level: 1,
        parentId: 0,
        id: itemCategories1.id,
      });

      categoriesLevel2
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map(itemCategories2 => {
          if (itemCategories2.parentId === itemCategories1.id) {
            result.options.push({
              label: itemCategories2.name,
              value: itemCategories2.legacyId,
              level: 2,
              parentId: itemCategories2.parentId,
              id: itemCategories2.id,
            });
          }
        });
    });

  result.categoriesLevel5 = categoriesLevel5;
  return result;
};

interface GetFilteredBrandLines {
  brandLines: BrandLine[];
  brandLineName: string;
  origin: string;
  owner: string;
}

type BrandLineFilterPropName = Extract<
  keyof BrandLine,
  'brandLineName' | 'originName' | 'ownerName'
>;

const filterByPropAndValue =
  (name: BrandLineFilterPropName, value: string) => (brandLine: BrandLine) => {
    return brandLine[name].toLowerCase().includes(value.toLowerCase());
  };

export const getFilteredBrandLines = ({
  brandLines,
  brandLineName,
  origin,
  owner,
}: GetFilteredBrandLines) => {
  let filteredBrandLines = [...brandLines];

  if (brandLineName) {
    filteredBrandLines = brandLines.filter(filterByPropAndValue('brandLineName', brandLineName));
  }

  if (origin) {
    filteredBrandLines = filteredBrandLines.filter(filterByPropAndValue('originName', origin));
  }

  if (owner) {
    filteredBrandLines = filteredBrandLines.filter(filterByPropAndValue('ownerName', owner));
  }

  return filteredBrandLines;
};
