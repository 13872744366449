import type { unsafe_ColDef, unsafe_SendToClipboardParams } from 'src/components/DataGrid';
import type { Entity } from '../../../types/Entity';
import { isRange } from './gridClipboardUtils';
import { copyRange } from './gridCopyRangeUtils';

const copyContent = async (
  colDef: unsafe_ColDef,
  data: Entity,
  cellValue: string,
  groupedRow: boolean,
  groupedColDef: unsafe_ColDef | undefined
) => {
  try {
    let valueToCopy = '';
    const fieldName: string = colDef.colId ?? '';
    const valueFieldName: string = fieldName.split('.')[0] ?? '';
    const valueFieldYear: string = fieldName.split('.')[1] ?? '';
    const refData = colDef.refData as Entity;

    if (!groupedRow) {
      const yearValues = data[valueFieldName] ? (data[valueFieldName] as Entity) : undefined;
      const yearValue = yearValues ? (yearValues[valueFieldYear] as keyof Entity) : undefined;
      const priceObject = yearValue ? (yearValue as unknown as Entity) : undefined;

      switch (colDef.type) {
        case 'entity':
        case 'select':
          valueToCopy = refData[cellValue] as string;
          break;

        case 'formula':
          switch (fieldName) {
            case '1yrChange':
            case '5yrCAGR':
            case '5yrFCAGR':
            case '3yrPRECOVIDCAGR':
            case 'onPremise.1yrChange':
            case 'onPremise.5yrCAGR':
            case 'allChannels.1yrChange':
            case 'allChannels.5yrCAGR':
              valueToCopy = cellValue
                ? (parseFloat(cellValue) * 100).toFixed(2) + '%'.toString()
                : '';

              break;

            case '5yrFAbsolute':
            case 'discrepancy':
              valueToCopy = cellValue ? parseFloat(cellValue).toFixed(2).toString() : '';
              break;

            default:
              valueToCopy = cellValue;
              break;
          }

          break;

        case 'number':
          switch (valueFieldName) {
            case 'volume':
            case 'forecast':
            case 'retailValue':
              valueToCopy =
                yearValue !== undefined ? parseFloat(yearValue).toFixed(2).toString() : '';

              break;

            case 'containerSize':
              valueToCopy = yearValue ? parseFloat(yearValue).toString() : '';
              break;

            case 'price':
              if (priceObject) {
                valueToCopy = (priceObject['isEstimated'] as keyof Entity)
                  ? '~' +
                    parseFloat(priceObject['value'] as keyof Entity)
                      .toFixed(2)
                      .toString()
                  : parseFloat(priceObject['value'] as keyof Entity)
                      .toFixed(2)
                      .toString();
              } else {
                valueToCopy = '';
              }
              break;

            case 'onPremisePercent':
              valueToCopy =
                yearValue !== undefined ? `${(parseFloat(yearValue) * 100).toFixed(0)}%` : '';
              break;

            case 'allChannelsVolume':
              valueToCopy = yearValue !== undefined ? `${parseFloat(yearValue).toFixed(2)}` : '';
              break;

            case 'providedVolume':
              valueToCopy = cellValue ? parseFloat(cellValue).toFixed(2).toString() : '';
              break;

            case 'percentage':
              valueToCopy = yearValue != null ? `${(parseFloat(yearValue) * 100).toFixed(2)}%` : '';
              break;

            default:
              valueToCopy = cellValue;
              break;
          }

          break;

        case 'text':
          switch (valueFieldName) {
            case 'percentage':
              valueToCopy = yearValue ? `${parseFloat(yearValue).toFixed(2)}%` : '';
              break;

            default:
              valueToCopy = cellValue;
          }

          break;

        default:
          valueToCopy = data[fieldName] as keyof Entity;

          break;
      }
    } else {
      switch (colDef.type) {
        case 'entity': {
          const refData = groupedColDef?.refData as Entity;
          valueToCopy = refData[cellValue] as string;
          break;
        }

        case 'linkedRecord':
        case 'text': {
          const refData = groupedColDef?.refData;
          if (refData) {
            valueToCopy = String(refData[cellValue]);
          } else {
            valueToCopy = cellValue;
          }
          break;
        }

        case 'number':
          switch (valueFieldName) {
            case 'volume':
            case 'forecast':
            case 'retailValue':
            case 'allChannelsVolume':
              valueToCopy = parseFloat(cellValue).toFixed(2).toString();
              break;
          }
          break;

        case 'formula':
          switch (valueFieldName) {
            case '1yrChange':
            case '5yrCAGR':
            case '5yrFCAGR':
            case '3yrPRECOVIDCAGR':
              valueToCopy = cellValue
                ? (parseFloat(cellValue) * 100).toFixed(2) + '%'.toString()
                : '';
          }
          break;

        default:
          valueToCopy = cellValue;
      }
    }
    await navigator.clipboard.writeText(valueToCopy);
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
};

export const sendToClipboard = (params: unsafe_SendToClipboardParams): void => {
  const ranges = params.api.getCellRanges();

  if (isRange(ranges)) {
    void copyRange(ranges![0]!, params.data, params.api);
    return;
  }

  const selectedRowIndex: number | undefined = params.api.getFocusedCell()?.rowIndex ?? undefined;

  if (selectedRowIndex !== undefined) {
    void copyContent(
      params.api.getFocusedCell()?.column.getColDef() as unknown as unsafe_ColDef,
      params.api.getDisplayedRowAtIndex(selectedRowIndex)?.data as unknown as Entity,
      params.data,
      params.api.getDisplayedRowAtIndex(selectedRowIndex)?.data ? false : true,
      params.api
        .getDisplayedRowAtIndex(selectedRowIndex)
        ?.rowGroupColumn?.getUserProvidedColDef() ?? undefined
    );
  }
};
