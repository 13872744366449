import uniqBy from 'lodash/uniqBy';

import type { unsafe_GridApi, unsafe_RowNode } from 'src/components/DataGrid';
import type { Market } from 'src/markets';
import type { DenormalizedBrandSales } from 'src/records/types/DenormalizedBrandSales';
import { getDenormalizedSaleData } from 'src/records/utils/denormalizers';
import type { Entity } from 'src/types/Entity';
import { calculateForecasts } from 'src/views/Pages/ForecastsTableViewPage/calculateForecasts';
import { getAffectedBrandLines } from 'src/views/utils/filters/getAffectedBrandLines';

interface Params {
  data: Record<string, Entity[]>;
  getEntitiesLookup: () => Record<string, Map<string, Entity>>;
  getEntities: (entityType: string) => Entity[];
  market: Market;
  dataGridApiRef: React.MutableRefObject<unsafe_GridApi | undefined>;
  getRowId: () => string;
  gridData: DenormalizedBrandSales[];
}

export const updateAgGridData = ({
  data,
  getEntitiesLookup,
  getEntities,
  market,
  dataGridApiRef,
  getRowId,
  gridData,
}: Params) => {
  const entitiesLookup = getEntitiesLookup();
  const affectedBrandLines = getAffectedBrandLines(entitiesLookup, data);

  const affectedBrandSales =
    data['brandSales']?.filter(item => item['countryId'] === market.marketId) ?? [];

  // if there are any changes on brand lines find which sales belong to these brand lines.
  if (affectedBrandLines.length > 0) {
    affectedBrandLines.forEach(brandLine => {
      affectedBrandSales.push(
        ...getEntities('brandSales')
          .filter(
            item =>
              item['brandLineGUID'] === brandLine['brandLineGUID'] &&
              item['countryId'] === market.marketId
          )
          .map(brandSale => getDenormalizedSaleData(brandSale, entitiesLookup))
      );
    });
  }

  // if there are any changes on distributors find which sales belong to these distributors.
  const distributors = data['distributors'] ?? [];
  if (distributors.length > 0) {
    distributors.forEach(distributor => {
      affectedBrandSales.push(
        ...getEntities('brandSales').filter(
          item => item['distributorGUID'] === distributor['distributorGUID']
        )
      );
    });
  }
  const uniqueAffectedBrandSales = uniqBy(affectedBrandSales, 'saleGUID');

  uniqueAffectedBrandSales.forEach(brandSale => {
    const gridIndex = gridData.findIndex(({ saleGUID }) => saleGUID === brandSale[getRowId()]);
    if (gridIndex < 0) {
      // brand sale doesn't exist. Must be a new one, so add it to grid data (list of all brandSales)
      gridData.push(getDenormalizedSaleData(brandSale, entitiesLookup) as DenormalizedBrandSales);
      recalculateAndUpdateAffectedForecastItem({
        brandSale: getDenormalizedSaleData(brandSale, entitiesLookup) as DenormalizedBrandSales,
        dataGridApiRef,
        getRowId,
        gridData,
      });
      return;
    }

    const originalGridItem = { ...gridData[gridIndex] } as DenormalizedBrandSales;
    const updatedGridItem = { ...gridData[gridIndex], ...brandSale } as DenormalizedBrandSales;

    gridData[gridIndex] = { ...updatedGridItem };

    /*
      recalcalculate for the previous Forecast item (i.e. matching old priceBand or isLocal or origin)
      */
    recalculateAndUpdateAffectedForecastItem({
      brandSale: originalGridItem,
      dataGridApiRef,
      getRowId,
      gridData,
    });
    /*
      now recalculate for the new Forecast item (i.e. matching new priceBand or isLocal or origin)
      */
    recalculateAndUpdateAffectedForecastItem({
      brandSale: updatedGridItem,
      dataGridApiRef,
      getRowId,
      gridData,
    });
  });
};

type ProcessParams = Pick<Params, 'dataGridApiRef' | 'getRowId' | 'gridData'> & {
  brandSale: DenormalizedBrandSales;
};

const recalculateAndUpdateAffectedForecastItem = ({
  brandSale,
  dataGridApiRef,
  getRowId,
  gridData,
}: ProcessParams) => {
  const matchingForecastItems = gridData.filter(gridItem => {
    const forecastKind = brandSale.forecastByOrigin ? 'originId' : 'isLocal';
    const isCategory5IdMatch = gridItem.category5Id === brandSale.category5Id;
    const isPriceBandIdMatch = gridItem.priceBandId === brandSale.priceBandId;
    const isOriginOrLocal = gridItem[forecastKind] === brandSale[forecastKind];
    return isCategory5IdMatch && isPriceBandIdMatch && isOriginOrLocal;
  });

  const newForecastData = calculateForecasts(matchingForecastItems);
  if (newForecastData.length <= 0) return;

  const newOrUpdatedForecastLine = { ...newForecastData[0] };

  let selectedNode: unsafe_RowNode | undefined;

  dataGridApiRef.current?.forEachNode(node => {
    if (node.data && (node.data as Entity)[getRowId()] === newOrUpdatedForecastLine[getRowId()]) {
      selectedNode = node;
      return;
    }
  });

  // check if the incoming forecast exists in the grid
  if (selectedNode) {
    // if the incoming forecast isDeleted, then remove the item from the grid.
    if (brandSale.isDeleted) {
      dataGridApiRef.current?.applyTransaction({ remove: [selectedNode.data] });
    } else {
      // update the existing forecast item in the grid.
      selectedNode.data = { ...newOrUpdatedForecastLine };
      dataGridApiRef.current?.applyTransaction({ update: [selectedNode.data] });
    }
  } else {
    // the incoming forecast item does not exist, so add it to the grid.
    dataGridApiRef.current?.applyTransaction({
      add: [{ ...newOrUpdatedForecastLine }],
    });
  }

  selectedNode = undefined;
};
