import categoryRulesJson from './categoryRules.json';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type CategoryRules = {
  name: string;
  id: number;
  alcoholicStrength: string[];
  flavoured: string[];
  ageStyle: string[];
  ageInYears: string[];
  craft: string[];
  maltScotchRegion: string[];
  brandLineExtension: string[];
};

export const standardRules = {
  name: 'Missing Category Rule',
  id: 0,
  alcoholicStrength: ['Alcoholic', 'Low Alcohol', 'No Alcohol'],
  flavoured: ['Non-Flavoured'],
  craft: [],
  maltScotchRegion: [],
  ageStyle: ['Non-Aged', 'Aged'],
  ageInYears: ['*'],
  brandLineExtension: [],
};

export const isRuleEnabled = (
  categoryRules: CategoryRules,
  ruleField: keyof Omit<CategoryRules, 'name' | 'id'>
): boolean => categoryRules[ruleField].length > 0;

export const isRuleWildcard = (
  categoryRules: CategoryRules,
  ruleField: keyof Omit<CategoryRules, 'name' | 'id'>
): boolean => categoryRules[ruleField].length === 1 && categoryRules[ruleField][0] === '*';

export const isRuleFixedValue = (
  categoryRules: CategoryRules,
  ruleField: keyof Omit<CategoryRules, 'name' | 'id'>
): boolean => categoryRules[ruleField].length === 1 && !isRuleWildcard(categoryRules, ruleField);

export const isRuleRange = (
  categoryRules: CategoryRules,
  ruleField: keyof Omit<CategoryRules, 'name' | 'id'>
): boolean => categoryRules[ruleField].length > 1;

export const categoriesRules: CategoryRules[] = categoryRulesJson;
