import { FilterOperator, type GridOptions, type Row } from './types';

export const COLUMN_TYPES: GridOptions<Row>['columnTypes'] = {
  formula: {
    cellClass: 'ag-right-aligned-cell',
    cellEditor: 'agTextCellEditor',
    filter: 'agNumberColumnFilter',
    filterParams: {
      filterOptions: [
        FilterOperator.Equals,
        FilterOperator.NotEqual,
        FilterOperator.LessThan,
        FilterOperator.LessThanOrEqual,
        FilterOperator.GreaterThan,
        FilterOperator.GreaterThanOrEqual,
        FilterOperator.Blank,
        FilterOperator.NotBlank,
      ],
      suppressAndOrCondition: true,
    },
    headerClass: 'ag-right-aligned-header',
  },
  linkedRecord: {
    cellClass: 'ag-left-aligned-cell',
    cellEditor: 'agTextCellEditor',
    filter: 'agSetColumnFilter',
    filterParams: {
      filterOptions: [FilterOperator.IsAnyOf],
      suppressAndOrCondition: true,
    },
    headerClass: 'ag-left-aligned-header',
  },
  number: {
    cellClass: 'ag-right-aligned-cell',
    cellEditor: 'agTextCellEditor',
    filter: 'agNumberColumnFilter',
    filterParams: {
      filterOptions: [
        FilterOperator.Equals,
        FilterOperator.NotEqual,
        FilterOperator.LessThan,
        FilterOperator.LessThanOrEqual,
        FilterOperator.GreaterThan,
        FilterOperator.GreaterThanOrEqual,
        FilterOperator.Blank,
        FilterOperator.NotBlank,
      ],
      suppressAndOrCondition: true,
    },
    headerClass: 'ag-right-aligned-header',
  },
  select: {
    cellClass: 'ag-left-aligned-cell',
    cellEditor: 'agSelectCellEditor',
    filter: 'agSetColumnFilter',
    filterParams: {
      filterOptions: [FilterOperator.IsAnyOf],
    },
    headerClass: 'ag-left-aligned-header',
  },
  entity: {
    cellClass: 'ag-left-aligned-cell',
    cellEditor: 'agSelectCellEditor',
    filter: 'agSetColumnFilter',
    filterParams: {
      filterOptions: [FilterOperator.IsAnyOf],
    },
    headerClass: 'ag-left-aligned-header',
  },
  text: {
    cellClass: 'ag-left-aligned-cell',
    cellEditor: 'agTextCellEditor',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: [
        FilterOperator.Equals,
        FilterOperator.NotEqual,
        FilterOperator.Contains,
        FilterOperator.NotContains,
        FilterOperator.StartsWith,
        FilterOperator.EndsWith,
        FilterOperator.Blank,
        FilterOperator.NotBlank,
      ],
      suppressAndOrCondition: true,
    },
    headerClass: 'ag-left-aligned-header',
  },
};

const context: GridOptions<Row>['context'] = {
  formulaFunctions: {},
};

const options: Partial<GridOptions<Row>> = {
  defaultColDef: {},
  enableRangeSelection: true,
  enableCellChangeFlash: true,
  ensureDomOrder: true,
  fillHandleDirection: 'y',
  groupDefaultExpanded: -1,
  groupDisplayType: 'custom',
  groupMaintainOrder: true,
  sideBar: false,
  suppressAggFuncInHeader: true,
  suppressColumnMoveAnimation: true,
  suppressCopySingleCellRanges: true,
  suppressDragLeaveHidesColumns: true,
  suppressLastEmptyLineOnPaste: true,
  suppressMakeColumnVisibleAfterUnGroup: true,
  suppressMultiRangeSelection: true,
  undoRedoCellEditing: true,
  undoRedoCellEditingLimit: 25,
  tooltipShowDelay: 500,
};

export const DEFAULT_GRID_OPTIONS: GridOptions<Row> = {
  ...(options as GridOptions<Row>),
  columnTypes: COLUMN_TYPES,
  context,
};
