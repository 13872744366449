import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { ColumnVisibilityMenu } from 'src/components/ColumnVisibilityMenu';
import { Container } from 'src/components/Container';
import {
  DataGrid,
  type unsafe_CellEditingStoppedEvent,
  type unsafe_GetContextMenuItemsParams,
  type unsafe_GridApi,
  type unsafe_GridOptions,
  type unsafe_NewValueParams,
  type unsafe_RowNode,
} from 'src/components/DataGrid';
import { GroupingMenu } from 'src/components/GroupingMenu';
import { SidebarBody, SidebarHeader, SidebarTitle, StyledSidebar } from 'src/components/Sidebar';
import { ViewSectionSwitcher } from 'src/components/ViewSectionSwitcher';
import { ViewSwitcher } from 'src/components/ViewSwitcher';
import { Content } from 'src/css/styled-components';
import { useDataGridUnmountKey } from 'src/hooks/useDataGridUnmountKey/useDataGridUnmountKey';
import { useRecords } from 'src/records/hooks/useRecords';
import type { DenormalizedBrandSales } from 'src/records/types/DenormalizedBrandSales';
import { useSyncContext } from 'src/sync';
import type { Entity, Volume } from 'src/types/Entity';
import { type BrandSalesEntity } from 'src/types/entity/BrandSales';
import type { WineColourSalesEntity } from 'src/types/entity/WineColourSales';
import { useGridViewState, useTable, useTitle, useViews } from 'src/views/hooks';
import { useColumns } from 'src/views/hooks/useColumns';
import { absoluteChange } from 'src/views/utils';
import { getColumnsWithSuffixedForecastYearHeaders } from 'src/views/utils/forecasting/grid';
import { sendToClipboard } from 'src/views/utils/grid/gridClipboardCopyUtils';
import { pasteFromClipboard } from 'src/views/utils/grid/gridClipboardPasteUtils';
import createContextMenu from '../common/getContextMenuItems';
import {
  calculateVolumesFromPercentages,
  getStillWineTotals,
  getUpdatedBrandSales,
} from './helpers';

import 'src/css/grid.css';

const STILL_WINE_CATEGORY3 = 41;

export const WineColourTableViewPage = () => {
  const navigate = useNavigate();
  const { market, table } = useTable();
  const { sections, views } = useViews(table);
  const { viewSlug } = useParams<'viewSlug'>();
  const { key } = useDataGridUnmountKey({ market });

  const { updateEntity, onEntitiesReceived } = useSyncContext();

  const dataGridApiRef = useRef<unsafe_GridApi>();

  const [brandSales, setBrandSales] = useState(
    useRecords<DenormalizedBrandSales>('brandSales', market.marketId).filter(
      item => item.category3Id === STILL_WINE_CATEGORY3
    )
  );
  let gridData = useRecords<WineColourSalesEntity>(table.source, market.marketId);
  const {
    stillWineVolumeTotals: stillWineSaleVolumes,
    stillWinePercentageTotals: percentageTotals,
  } = getStillWineTotals(brandSales, gridData);
  gridData = calculateVolumesFromPercentages(stillWineSaleVolumes, gridData);

  const updateAgGridData = (data: Record<string, WineColourSalesEntity[]>) => {
    // get only this market data from provider.
    const wineColourData =
      data[table.source]?.filter(item => item.countryId === market.marketId) ?? [];

    let selectedNode: unsafe_RowNode | undefined;

    wineColourData.forEach(wineColourEntity => {
      // extra control for skipping sales that does not belong to this market.
      if (wineColourEntity.countryId !== market.marketId) {
        return;
      }

      gridData = calculateVolumesFromPercentages(stillWineSaleVolumes, [
        ...gridData.filter(
          item =>
            item[table.primaryField as keyof typeof item] !==
            wineColourEntity[table.primaryField as keyof typeof wineColourEntity]
        ),
        wineColourEntity,
      ]);

      gridData.forEach(wineColour => {
        dataGridApiRef.current?.forEachNode(node => {
          if (
            node.data &&
            (node.data as Entity)[getRowId()] ===
              wineColour[getRowId() as keyof WineColourSalesEntity]
          ) {
            selectedNode = node;
            return;
          }
        });

        if (selectedNode) {
          // update the existing ag grid wine colour sale item.
          selectedNode.data = {
            ...wineColour,
          };
          dataGridApiRef.current?.applyTransaction({ update: [selectedNode.data] });
        }
      });

      const { stillWinePercentageTotals: percentageTotals } = getStillWineTotals(
        brandSales.filter(item => item.category3Id === STILL_WINE_CATEGORY3),
        gridData
      );

      dataGridApiRef.current?.setPinnedTopRowData([
        {
          colour: `${market.marketName.toUpperCase()}: STILL WINE`,
          volume: stillWineSaleVolumes,
          percentage: percentageTotals,
        },
      ]);
    });
  };

  const updateAgGridWithSalesTableData = (brandSaleEntities: BrandSalesEntity[]) => {
    const updateBrandSales = getUpdatedBrandSales({ brandSales, brandSaleEntities, market });
    setBrandSales(updateBrandSales);

    brandSaleEntities.forEach(() => {
      const {
        stillWineVolumeTotals: stillWineSaleVolumes,
        stillWinePercentageTotals: percentageTotals,
      } = getStillWineTotals(updateBrandSales, gridData);

      let selectedNode: unsafe_RowNode | undefined;

      gridData.forEach(wineColour => {
        dataGridApiRef.current?.forEachNode(node => {
          if (
            node.data &&
            (node.data as Entity)[getRowId()] ===
              wineColour[getRowId() as keyof WineColourSalesEntity]
          ) {
            selectedNode = node;
            return;
          }
        });

        if (selectedNode) {
          // update the existing ag grid wine colour sale item.
          selectedNode.data = {
            ...wineColour,
          };
          dataGridApiRef.current?.applyTransaction({ update: [selectedNode.data] });
        }
      });

      dataGridApiRef.current?.setPinnedTopRowData([
        {
          colour: `${market.marketName.toUpperCase()}: STILL WINE`,
          volume: stillWineSaleVolumes,
          percentage: percentageTotals,
        },
      ]);
    });
  };

  useEffect(() => {
    // When data received from other tabs or received from delta sync, update AG-GRID data without re-rendering.
    return onEntitiesReceived((data: Record<string, Entity[]>) => {
      if (data[table.source]) {
        updateAgGridData(data as unknown as Record<string, WineColourSalesEntity[]>);
      }

      if (data['brandSales']) {
        updateAgGridWithSalesTableData(data['brandSales'] as unknown as BrandSalesEntity[]);
      }
    });
  });

  const view = useMemo(() => views.find(view => view.slug === viewSlug), [views, viewSlug]);

  const section = useMemo(
    () => sections.find(section => section.id === view?.sectionId),
    [sections, view]
  );

  useEffect(() => {
    if (!viewSlug) {
      if (!views[0]) throw new Error('No views can be found.');

      navigate(views[0].slug, { replace: true });
    }
  }, [navigate, views, viewSlug]);

  useTitle(`${market.marketName}: ${table.name} - Collector`);

  const { convertFieldsToColumns } = useColumns();

  const { columns, columnGrouping } = useMemo(
    () => {
      const columnsAndColumnGrouping = convertFieldsToColumns(table.fields, {
        lockedView: Boolean(view?.locked),
        periodiseByRows: false,
      });

      return {
        ...columnsAndColumnGrouping,
        columns: getColumnsWithSuffixedForecastYearHeaders(columnsAndColumnGrouping.columns),
      };
    },
    // Exclude convertFieldsToColumns from list to prevent redraw
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table.fields, view?.tableId, view?.locked]
  );

  const {
    handleColumnOrderChange,
    handleColumnVisibilityChange,
    handleColumnWidthsChange,
    handlePinnedColumnsChange,
    handleRowGroupingChange,
    state,
  } = useGridViewState(table);

  const formulaFunctions = useMemo(() => ({ absoluteChange }), []);

  const getContextMenuItems = (params: unsafe_GetContextMenuItemsParams<Entity>) =>
    createContextMenu(params, table.canRemoveRecords);

  const pinnedColumns = useMemo(
    () => state.pinnedColumns ?? { left: view?.pinnedColumns ?? [] },
    [view, state]
  );

  const getRowId = useCallback(() => table.primaryField, [table.primaryField]);

  const handleCellEditingStopped = useCallback(
    ({ api, data }: unsafe_CellEditingStoppedEvent<Entity>) => {
      const changedDataIndex = gridData.findIndex(
        ({ wineColourGUID }) => wineColourGUID === data['wineColourGUID']
      );

      gridData[changedDataIndex] = data as unknown as WineColourSalesEntity;

      const { stillWinePercentageTotals: percentageTotals } = getStillWineTotals(
        brandSales.filter(item => item.category3Id === STILL_WINE_CATEGORY3),
        gridData
      );

      api.setPinnedTopRowData([
        {
          colour: `${market.marketName.toUpperCase()}: STILL WINE`,
          volume: stillWineSaleVolumes,
          percentage: percentageTotals,
        },
      ]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleCellValueChange = useCallback(
    ({ column, data, newValue, node }: unsafe_NewValueParams<Entity>) => {
      const columnId = column.getColId();

      // Volume is updated concurrently with percentage, so make sure logic doesn't run for the volume column as well.
      if (columnId.startsWith('percentage')) {
        const entityGuid = String(data[table.primaryField]);
        const [columnName, year] = columnId.split('.') as [string, string];
        const payload = {
          [columnName]: {
            [year]: newValue as number,
          },
        };
        const { stillWineVolumeTotals } = getStillWineTotals(
          brandSales.filter(item => item.category3Id === STILL_WINE_CATEGORY3),
          gridData
        );

        if (node?.data) {
          const newVolume =
            newValue != null
              ? (stillWineVolumeTotals[year as unknown as number] ?? 0) * newValue
              : null;

          const wineColourSalesEntity = node.data;

          wineColourSalesEntity['volume'] = {
            ...(wineColourSalesEntity['volume'] as Volume),
            [year]: newVolume,
          } as Volume;

          dataGridApiRef.current?.applyTransaction({
            update: [wineColourSalesEntity],
          });
        }

        void updateEntity(table.source, entityGuid, payload);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table]
  );

  const handleGridReady = useCallback(
    (params: unsafe_GridOptions) => {
      if (params.api) {
        dataGridApiRef.current = params.api;
        params.api.setPinnedTopRowData([
          {
            colour: `${market.marketName.toUpperCase()}: STILL WINE`,
            volume: stillWineSaleVolumes,
            percentage: percentageTotals,
          },
        ]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!viewSlug) return <p>Loading.</p>;

  if (!view) return <p>View not found.</p>;

  return (
    <>
      <Navbar bg="light" expand="sm" id="viewBar" className="py-0">
        <Nav>
          <ColumnVisibilityMenu
            columnOrder={state.columnOrder}
            allTableFields={table.fields}
            columnVisibility={state.columnVisibility}
            pinnedColumns={state.pinnedColumns}
            onColumnOrderChange={handleColumnOrderChange}
            onColumnVisibilityChange={handleColumnVisibilityChange}
            onColumnPinnedChange={handlePinnedColumnsChange}
            disableChangingVisibility={!table.canChangeColumnVisibility}
            disableChangingOrder={!table.canChangeColumnOrder}
          />
          <GroupingMenu
            columns={table.fields}
            disabled={!table.canChangeRowGrouping || view.locked}
            rowGrouping={view.locked ? view.rowGrouping : state.rowGrouping}
            onRowGroupingChange={handleRowGroupingChange}
          />
        </Nav>
      </Navbar>

      <Container id="viewContainer">
        {views.length > 1 && (
          <StyledSidebar sidebarOpen data-testid="sidebar">
            <SidebarHeader sidebarOpen>
              <SidebarTitle>Options</SidebarTitle>
            </SidebarHeader>
            <SidebarBody sidebarOpen>
              {sections.length > 0 && (
                <ViewSectionSwitcher currentSection={section} currentView={view} />
              )}
              <ViewSwitcher currentSection={section} currentView={view} />
            </SidebarBody>
          </StyledSidebar>
        )}
        <Content>
          <DataGrid<Entity>
            key={key}
            aggregation={view.aggregation}
            canChangeColumnOrder={table.canChangeColumnOrder}
            canChangeColumnVisibility={false}
            canChangeRowGrouping={table.canChangeRowGrouping}
            columnGrouping={columnGrouping}
            columnOrder={state.columnOrder}
            columns={columns}
            columnVisibility={state.columnVisibility}
            columnWidths={state.columnWidths}
            onCellValueChange={handleCellValueChange}
            filter={view.filter}
            formulaFunctions={formulaFunctions}
            getRowId={getRowId}
            groupingColumn={{ leafField: view.leafField }}
            onColumnOrderChange={handleColumnOrderChange}
            onColumnVisibilityChange={handleColumnVisibilityChange}
            onColumnWidthChange={handleColumnWidthsChange}
            onPinnedColumnsChange={handlePinnedColumnsChange}
            onRowGroupingChange={handleRowGroupingChange}
            onGridReady={handleGridReady}
            pinnedColumns={pinnedColumns}
            rows={gridData as unknown as Entity[]}
            rowGrouping={view.locked ? view.rowGrouping : state.rowGrouping}
            sort={view.sort}
            getContextMenuItems={getContextMenuItems}
            sendToClipboard={sendToClipboard}
            processDataFromClipboard={pasteFromClipboard}
            enterMovesDownAfterEdit
            enterMovesDown
            onCellEditingStopped={handleCellEditingStopped}
          />
        </Content>
      </Container>
    </>
  );
};
