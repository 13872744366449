import type { MutableRefObject } from 'react';

import type { DataGridProps } from '../../DataGrid';
import type { GridOptions, Row } from '../../types';
import { createColumnDef } from '../../utils';
import { useEffectAfterFirstRender, useGridLogger, useInitialiseState } from '../utils';

export const useColumnsFeature = <R extends Row>(
  gridRef: MutableRefObject<GridOptions<R>>,
  props: Pick<
    DataGridProps<R>,
    | 'columns'
    | 'filter'
    | 'filterIsViewDefined'
    | 'formulaFunctions'
    | 'canChangeColumnVisibility'
    | 'canChangeColumnOrder'
  >
) => {
  const logger = useGridLogger(gridRef, 'useColumnsFeature');

  const {
    columns,
    filter,
    filterIsViewDefined,
    formulaFunctions = {},
    canChangeColumnVisibility = true,
    canChangeColumnOrder = true,
  } = props;

  useInitialiseState(() => {
    const colDefs = columns.map(column =>
      createColumnDef<R>(column, {
        canChangeColumnVisibility,
        canChangeColumnOrder,
        filter,
        filterIsViewDefined,
      })
    );

    gridRef.current.columnDefs = colDefs;
    gridRef.current.context.formulaFunctions = formulaFunctions;
  });

  useEffectAfterFirstRender(() => {
    logger.log(`Columns have changed, new length ${columns.length}`);

    const colDefs = columns.map(column =>
      createColumnDef<R>(column, {
        canChangeColumnVisibility,
        canChangeColumnOrder,
        filter,
        filterIsViewDefined,
      })
    );
    gridRef.current.api?.setColumnDefs(colDefs);
  }, [columns, canChangeColumnVisibility, canChangeColumnOrder]);

  useEffectAfterFirstRender(() => {
    logger.log('Updating formula functions');

    gridRef.current.context.formulaFunctions = formulaFunctions;
  }, [formulaFunctions]);
};
