import type { FlatCategoryItem } from '../views/types';
import type { BooleanOption, NumericOption } from './createBrandLineFormMappers';
import { emptyOption } from './createBrandLineFormMappers';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type CreateBrandLineState = {
  category?: FlatCategoryItem;
  errorMessages: Record<string, string>;
  displayName: string;
  ageInYears: number;
  ageInYearsVisible: boolean;
  ageInYearsAvailable: boolean;
  ageStyle: NumericOption;
  ageStyleOptions: NumericOption[];
  ageStyleVisible: boolean;
  ageStyleDisabled: boolean;
  origin: string;
  originDisabled: boolean;
  brandLineTypeId: NumericOption;
  flavoured: boolean;
  flavouredOptions: BooleanOption[];
  flavouredDisabled: boolean;
  flavouredVisible: boolean;
  craft: boolean;
  craftOptions: BooleanOption[];
  craftVisible: boolean;
  alcoholicStrength: NumericOption;
  alcoholicStrengthOptions: NumericOption[];
  alcoholicStrengthDisabled: boolean;
  alcoholicStrengthVisible: boolean;
  ble: NumericOption;
  bleOptions: NumericOption[];
  bleVisible: boolean;
  maltRegion: NumericOption;
  maltRegionOptions: NumericOption[];
  maltRegionVisible: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  brandLine: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  brandLineName?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  brandName?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  brandGUID?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parentBrand: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  brandOwner: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ownerGUID?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ownerName?: any;
  isNewParentBrand: boolean;
  isNewParentBrandConfirmed: boolean;
  attributesDisabled: boolean;
  parentBrandDisabled: boolean;
  brandOwnerDisabled: boolean;
  modalBrandOrOwner?: string;
  modalOpen?: boolean;
  modalInputType?: string;
  modalType?: string;
  modalHeader?: string;
  modalInputLabel?: string;
  countryId?: number;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type CreateBrandLineAction = {
  type: 'update' | 'reset';
  payload?: Partial<CreateBrandLineState>;
};

export const initialState = {
  errorMessages: {},
  displayName: '',
  ageInYears: 0,
  ageInYearsVisible: false,
  ageInYearsAvailable: false,
  ageStyle: emptyOption,
  ageStyleOptions: [],
  ageStyleVisible: true,
  ageStyleDisabled: false,
  origin: '',
  originDisabled: false,
  brandLineTypeId: { id: 0, text: 'Branded' },
  flavoured: false,
  flavouredOptions: [],
  flavouredDisabled: true,
  flavouredVisible: true,
  craft: false,
  craftOptions: [],
  craftVisible: false,
  alcoholicStrength: emptyOption,
  alcoholicStrengthOptions: [],
  alcoholicStrengthDisabled: false,
  alcoholicStrengthVisible: true,
  ble: emptyOption,
  bleOptions: [],
  bleVisible: false,
  maltRegion: emptyOption,
  maltRegionOptions: [],
  maltRegionVisible: false,
  brandLine: undefined,
  parentBrand: undefined,
  brandOwner: undefined,
  isNewParentBrand: false,
  isNewParentBrandConfirmed: false,
  attributesDisabled: false,
  parentBrandDisabled: false,
  brandOwnerDisabled: true,
  brandOrOwnerName: '',
};

export const createBrandLineReducer = (
  state: CreateBrandLineState,
  action: CreateBrandLineAction
): CreateBrandLineState => {
  switch (action.type) {
    case 'update':
      return {
        ...state,
        ...action.payload,
      };
    case 'reset':
      return initialState;
    default:
      throw new Error('Reducer Error');
  }
};
