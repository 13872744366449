import type { FC } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import type { CreateBrandLineSectionProps } from 'src/types/CreateBrandLineTypes';
import { buildNumericValuesHandler } from 'src/utils/createBrandLineFormHandlers';
import { mapNumericsToValues, mapNumericToValue } from 'src/utils/createBrandLineFormMappers';
import { Label } from './styledComponents';

const CreateBrandLineBleSection: FC<CreateBrandLineSectionProps> = ({
  dispatchFormAction,
  formState,
}) => {
  if (!formState.category) return null;

  return (
    <Form.Group>
      <Label>Brand Line Extension</Label>
      <Select
        options={mapNumericsToValues(formState.bleOptions)}
        value={mapNumericToValue(formState.ble)}
        onChange={buildNumericValuesHandler(dispatchFormAction, 'ble')}
        isDisabled={formState.attributesDisabled}
        data-testid="create_brand_line_ble_dropdown"
        id="create_brand_line_ble_dropdown"
      />
    </Form.Group>
  );
};

export default CreateBrandLineBleSection;
