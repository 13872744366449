import { type unsafe_GridApi } from 'src/components/DataGrid';
import { type DenormalizedBrandSales } from 'src/records/types/DenormalizedBrandSales';

export const dataSanityCheck = (dataGridApi: unsafe_GridApi<DenormalizedBrandSales>) => {
  dataGridApi.forEachNodeAfterFilterAndSort(rowNode => {
    if (rowNode.leafGroup) {
      const segments = rowNode.allLeafChildren.map(leafNodes => leafNodes.data?.priceBandName);
      const segmentsThatAppearMoreThanOnce = segments.filter(
        (item, index) => segments.indexOf(item) !== index
      );
      if (segmentsThatAppearMoreThanOnce.length) {
        segmentsThatAppearMoreThanOnce.forEach(segment => {
          console.warn(
            `Forecast segment "${segment!}" appears more than once!`,
            `saleGUIDs: `,
            rowNode.allLeafChildren
              .filter(leafNodes => leafNodes.data?.priceBandName === segment)
              .map(leafNodes => leafNodes.data?.saleGUID)
          );
        });
      }
    }
  });
};
